import api from "helpers/api";

import {
  STAT_USERS_REQUEST,
  STAT_USERS_SUCCESS,
  STAT_USERS_FAIL,
  STAT_LECTIONS_REQUEST,
  STAT_LECTIONS_SUCCESS,
  STAT_LECTIONS_FAIL,
} from "сonstants/statConstants";

// GET USERS STATS (ADMIN)
export const listUsersStats = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STAT_USERS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const users = await api.get(`/stats/users`, config);

    dispatch({
      type: STAT_USERS_SUCCESS,
      payload: users.data,
    });
  } catch (error) {
    dispatch({
      type: STAT_USERS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// GET LECTIONS STATS (ADMIN)
export const listLectionsStats = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STAT_LECTIONS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const lections = await api.get(`/stats/lections`, config);

    dispatch({
      type: STAT_LECTIONS_SUCCESS,
      payload: lections.data,
    });
  } catch (error) {
    dispatch({
      type: STAT_LECTIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
