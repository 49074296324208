import React from "react";

function InputBlock({ label, children }) {
  return (
    <div className="input__block">
      <label htmlFor="">{label}</label>
      {children}
    </div>
  );
}

export default InputBlock;
