// USER LOGIN
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";

// USER REGISTER
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

// USER LOGOUT
export const USER_LOGOUT = "USER_LOGOUT";

// USER FORGOT PASSWORD
export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";

// USER RESTORE PASSWORD
export const USER_RESTORE_PASSWORD_REQUEST = "USER_RESTORE_PASSWORD_REQUEST";
export const USER_RESTORE_PASSWORD_SUCCESS = "USER_RESTORE_PASSWORD_SUCCESS";

// GET USER INFO
export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAIL = "USER_INFO_FAIL";

// GET ALL USERS
export const USERS_ALL_REQUEST = "USERS_ALL_REQUEST";
export const USERS_ALL_SUCCESS = "USERS_ALL_SUCCESS";
export const USERS_ALL_FAIL = "USERS_ALL_FAIL";
