// RESET SUBSCRIPTION
export const SUBSCRIPTION_RESET = "SUBSCRIPTION_RESET";

// SUBSCRIBE TO LECTION
export const SUBSCRIBE_LECTION_REQUEST = "SUBSCRIBE_LECTION_REQUEST";
export const SUBSCRIBE_LECTION_SUCCESS = "SUBSCRIBE_LECTION_SUCCESS";
export const SUBSCRIBE_LECTION_FAIL = "SUBSCRIBE_LECTION_FAIL";

// UNSUBSCRIBE TO LECTION
export const UNSUBSCRIBE_LECTION_REQUEST = "UNSUBSCRIBE_LECTION_REQUEST";
export const UNSUBSCRIBE_LECTION_SUCCESS = "UNSUBSCRIBE_LECTION_SUCCESS";
export const UNSUBSCRIBE_LECTION_FAIL = "UNSUBSCRIBE_LECTION_FAIL";

// GET SUBSCRIPTION CURRENT USER FOR SINGLE LECTION
export const SUBSCRIPTION_GET_REQUEST = "SUBSCRIPTION_GET_REQUEST";
export const SUBSCRIPTION_GET_SUCCESS = "SUBSCRIPTION_GET_SUCCESS";
export const SUBSCRIPTION_GET_FAIL = "SUBSCRIPTION_GET_FAIL";
