import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_RESET,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_RESET,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAIL,
  USERS_ALL_REQUEST,
  USERS_ALL_SUCCESS,
  USERS_ALL_FAIL,
} from "сonstants/userConstants";

// User Login
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGIN_RESET:
      return { loading: false };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

// User Register
export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return { loading: false };
    default:
      return state;
  }
};

// User Info
export const currentUserReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_INFO_REQUEST:
      return { loading: true, ...state };
    case USER_INFO_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// Users All
export const usersListReducer = (state = { usersList: [] }, action) => {
  switch (action.type) {
    case USERS_ALL_REQUEST:
      return { loading: true, ...state };
    case USERS_ALL_SUCCESS:
      return { loading: false, usersList: action.payload };
    case USERS_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
