import React from "react";
import { useSelector } from "react-redux";

function Overlay() {
  // REDUX STATE
  const overlay = useSelector((state) => state.ui.overlay);
  const { isActive } = overlay;

  return <>{isActive ? <div className="overlay"></div> : null}</>;
}

export default Overlay;
