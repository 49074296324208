import React from "react";

function InputStandart({ type, placeholder, value, onChange, subtext }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        className="input__default"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <small>{subtext}</small>
    </div>
  );
}

export default InputStandart;
