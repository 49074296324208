import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CONSTANTS
import { PAYMENT_STATUS_RESET } from "../сonstants/paymentConstants";

function usePaymentStatus({ getCurrentValue }) {
  const dispatch = useDispatch();

  // REDUX STATE
  const paymentStatusState = useSelector((state) => state.payment.status);
  const { status } = paymentStatusState;

  useEffect(() => {
    getCurrentValue();

    return () => {
      dispatch({ type: PAYMENT_STATUS_RESET });
    };
  }, [dispatch, getCurrentValue]);

  const isPaid = status ? status.isPaid : false;
  const ticketsAmount = status ? status.ticketsAmount : 0;

  return { isPaid, ticketsAmount };
}

export default usePaymentStatus;
