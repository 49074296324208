import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import { listAllUsers } from "redux/actions/userActions";
import { listUsersStats } from "redux/actions/statActions";

// COMPONENTS
import Spinner from "components/Loaders/Spinner";

// import Pagination from "components/Buttons/Pagination";

// LOCAL COMPONENTS
import CardUser from "./components/CardUser";
import SectionStatAdmin from "../components/SectionStatAdmin";
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function UsersList() {
  const dispatch = useDispatch();

  // GET USERS
  const users = useSelector((state) => state.users);
  const { loading, usersList } = users;

  // GET USERS STATS
  const usersStats = useSelector((state) => state.stats.usersStats);
  const { users: stats } = usersStats;

  useEffect(() => {
    dispatch(listAllUsers("", 30, "-createdAt"));
    dispatch(listUsersStats());
  }, [dispatch]);

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Управление Пользователями" />
      <SectionStatAdmin
        data={[
          {
            id: 1,
            title: "Пользователи",
            stats: [
              {
                id: 1,
                title: "Всего",
                data: stats.users,
              },
              {
                id: 2,
                title: "Не подтвердили почту",
                data: stats.usersEmailUnconfirmed,
              },
              {
                id: 3,
                title: "Имеют мероприятие",
                data: stats.usersHaveLection,
              },
            ],
          },
        ]}
      />
      <section className="section__admin--users">
        <div className="container">
          {loading || !usersList.data ? (
            <Spinner />
          ) : (
            <>
              <div className="grid-container">
                {usersList.data.map((user) => (
                  <CardUser key={user._id} data={user}></CardUser>
                ))}
              </div>
            </>
          )}
          {/* <Pagination
            pages={usersList.pagesTotal ? usersList.pagesTotal : []}
          /> */}
        </div>
      </section>
    </main>
  );
}

export default UsersList;
