import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CONSTANTS
import { UI_NOTIFICATION_SHOW } from "сonstants/uiConstants";

// ACTIONS
import { createNewPassword } from "redux/actions/userActions";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

// LOCAL COMPONENTS
import AuthInput from "./components/Input";

function CreateNewPassword({ location, history, match }) {
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const resetToken = match.params.token;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (password === passwordCheck) {
      dispatch(createNewPassword(resetToken, password, history, location));
    } else {
      const notifications = [
        {
          type: "slide",
          color: "#ff6c65",
          title: "Пароли отличаются",
          icon: "fal fa-exclamation-circle",
        },
      ];
      dispatch({
        type: UI_NOTIFICATION_SHOW,
        payload: notifications,
      });
    }
  };
  return (
    <main className="margined-navbar">
      <section className="section--auth-create-new-password">
        <div className="container">
          <div className="content-bar">
            <BackPageBtn />
          </div>
          <div className="auth-container">
            <div className="column">
              <div className="column-header">
                <div className="icon">
                  <i className="far fa-user-edit"></i>
                </div>
                <div className="auth-links">
                  <p className="active-auth-link">Создание Нового Пароля</p>
                </div>
              </div>
              <form onSubmit={handleFormSubmit} className="auth-form">
                <AuthInput
                  icon="far fa-lock-alt"
                  type="password"
                  placeholder="Новый пароль"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <AuthInput
                  icon="far fa-lock-alt"
                  type="password"
                  placeholder="Повторите пароль"
                  value={passwordCheck}
                  onChange={(e) => setPasswordCheck(e.target.value)}
                />

                <button className="btn btn-x1 btn-green-fill" type="submit">
                  Подтвердить
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default CreateNewPassword;
