import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobCopywriter() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Копирайтер</h4>
          <div className="content">
            <p>
              Каждый день у нас происходит куча всего, о чем нам хотелось бы
              рассказывать. Поэтому нам нужен человек, который разбирается в
              соцсетях и умеет хорошо и интересно писать, чтобы делиться всем с
              нашими соратниками и привлекать новых клиентов.
            </p>
            <li>
              — Мы находимся в Верхней Пышме, поэтому каждый день по утрам и
              вечерам предусмотрен трансфер от метро Уралмаш и обратно;
            </li>
            <li>
              — Средний возраст наших сотрудников — 26 лет. Практически у всех
              высшее, чаще всего, гуманитарное образование. Все влюблены в свою
              работу без всякой корпоративной пропаганды и прочей ерунды,
              поэтому дружны и инициативны. Токсичных товарищей мы отсеиваем
              независимо от их личной продуктивности;
            </li>
            <li>
              — Кофейня работает с 7 утра, магазин с 10, а закрываются все
              дружно в 9 вечера — каждый день без выходных. Поэтому каждый
              сотрудник офиса выбирает себе удобный график, вписывающийся в эти
              границы: кому-то удобнее с понедельника по пятницу с 8 до 17,
              кому-то со среды по воскресенье с полудня до закрытия, но большая
              часть коллектива приезжает к 10 и уезжает в 19 часов с выходными
              по субботам и воскресеньям;
            </li>
            <li>
              — Благодаря собственной кофейне и площадке для мероприятий,
              рабочий день может быть весьма разнообразным и насыщенным с
              периодической сменой локаций;
            </li>
            <p className="font-bold">Что мы предлагаем:</p>
            <li>
              — работа в Верхней Пышме в интересном проекте среди
              единомышленников; красивое здание, вкусный кофе, регулярные
              мероприятия;
            </li>
            <li>— гибкий посменный график;</li>
            <li>— развоз до работы и обратно;</li>
            <li>
              — белый оклад и не менее белая премия по результатам продаж 2 раза
              в месяц без задержек;
            </li>
            <li>— скидки на книги, бесплатный кофе.</li>
            <p className="font-bold">Что надо делать:</p>
            <p>
              Работа по этой вакансии не предполагает удаленки. Ведение наших
              соцсетей требует постоянного нахождения в процессе, прямого
              общения с резидентами, сотрудниками кофейни и лектория, участия в
              нашей повседневной жизни.{" "}
            </p>
            <p>
              Нам очень пригодится опыт в составлении контент-стратегии, с
              метриками и постановкой конкретных задач. При этом, мы ищем
              человека, который будет писать не только для соцсетей, но и всюду,
              где нужны тексты: вакансии, рекламные статьи, публикации для
              журналов и тп. Вам потребуется умение не только литературно
              перерабатывать чужие слова, но и самостоятельно вникать в тему,
              искать материал в книгах и в интернете.{" "}
            </p>
            <p>
              Сейчас вам достаточно написать на электронную почту
              <a href="mailto: ssv@drugie-knigi.ru"> ssv@drugie-knigi.ru</a> с
              темой письма «Кандидат копирайтер». В ответ мы отправим вам
              небольшую анкету. Если от нас не будет ответа больше трех дней,
              проверьте папку «Спам», а потом продублируйте ваше письмо.
            </p>
            <p>
              Если и это не помогло, напишите нам в соцсетях или позвоните по
              телефону <a href="tel:+79122000199">+79122000199</a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobCopywriter;
