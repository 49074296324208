import React from "react";

function ToggleBtn({ isChecked, onClick }) {
  return (
    <label className="btn-toggle" onClick={onClick}>
      <input type="checkbox" defaultChecked={isChecked} />
      <span className="slider round"></span>
    </label>
  );
}

export default ToggleBtn;
