import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobBarista() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Бариста в кофейню</h4>
          <div className="content">
            <p className="font-bold">Добрый день!</p>
            <p className="font-bold">
              Мы ищем веселых, активных, коммуникабельных людей, которые:
            </p>
            <li>— любят кофе</li>
            <li>— активно интересуются кофейной индустрией</li>
            <li>— заинтересованы в профессиональном развитии</li>
            <li>— любознательны, имеют много интересов или хобби</li>
            <li>— имеют опыт работы в сфере</li>
            <p className="font-bold">Обязанности:</p>
            <li>— хорошо варить кофе, готовить авторские напитки и чаи;</li>
            <li>— общаться с гостями, консультировать их по меню;</li>
            <li>— нести ответственность за свое рабочее место</li>
            <li>— следить за порядком на десертной витрине</li>
            <li>— работать с кассой</li>
            <li>— готовить кофейню к открытию и закрытию</li>
            <p className="font-bold">Что мы предлагаем:</p>
            <li>
              первичное обучение в нашей школе бариста и регулярное повышение
              квалификации у лидеров индустрии, в том числе приглашенных из
              других городов;
            </li>
            <li>
              — официальное трудоустройство, выплачивается 2 раза в месяц без
              задержек и переносов;
            </li>
            <li>— работа с топовым оборудованием;</li>
            <li>— гибкий график;</li>
            <li>— скидки на книги и мероприятия.</li>
            <li>— доставка на рабочую смену и развоз служебным транспортом</li>
            <p>
              Ждем ваше резюме на почту{" "}
              <a href="mailto: kirilina.anna.barista@gmail.com">
                kirilina.anna.barista@gmail.com
              </a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobBarista;
