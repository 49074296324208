import React, { Suspense } from "react";
import ROUTES, { RenderRoutes } from "./routes";

// COMPONENTS
import SpinnerLoader from "./components/Loaders/Spinner";
import NavFixed from "./components/Navigation/Navbar";
import SlideNotification from "./components/Notification/Notifications";
import Overlay from "./components/Overlay/Overlay";
import Sidebar from "./components/Navigation/Sidebar";
import Footer from "./components/Footer/Footer";

// MIDDLEWARE
import ScrollToTop from "./middleware/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Overlay />
      <NavFixed />
      <Sidebar />
      <SlideNotification position="top-right" />
      <Suspense fallback={<SpinnerLoader />}>
        <RenderRoutes routes={ROUTES} />
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
