import api from "helpers/api";

import {
  SUBSCRIBE_LECTION_REQUEST,
  SUBSCRIBE_LECTION_SUCCESS,
  SUBSCRIBE_LECTION_FAIL,
  UNSUBSCRIBE_LECTION_REQUEST,
  UNSUBSCRIBE_LECTION_SUCCESS,
  UNSUBSCRIBE_LECTION_FAIL,
  SUBSCRIPTION_GET_REQUEST,
  SUBSCRIPTION_GET_SUCCESS,
  SUBSCRIPTION_GET_FAIL,
} from "сonstants/subscribeConstants";

// SUBSCRIBE LECTION (PROTECTED)
export const subscribeToLection =
  (id, amount) => async (dispatch, getState) => {
    try {
      dispatch({ type: SUBSCRIBE_LECTION_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUESTS
      await api.post(`/subscribe/${id}?amount=${amount}`, {}, config);

      dispatch({
        type: SUBSCRIBE_LECTION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SUBSCRIBE_LECTION_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// UNSUBSCRIBE LECTION (PROTECTED)
export const unSubscribeToLection = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNSUBSCRIBE_LECTION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    await api.delete(`/subscribe/${id}`, config);

    dispatch({
      type: UNSUBSCRIBE_LECTION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UNSUBSCRIBE_LECTION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// GET SUBSCRIPTION STATUS (PROTECTED)
export const getSubscriptionStatus = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SUBSCRIPTION_GET_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    const { data } = await api.get(`/subscribe/${id}`, config);

    dispatch({
      type: SUBSCRIPTION_GET_SUCCESS,
      payload: data.isSubscribed,
    });
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_GET_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
