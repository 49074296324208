import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";
import Button from "components/Buttons/Button";

function SectionHeaderAdmin({ title, events }) {
  return (
    <section className="section__admin--header">
      <div className="container">
        <div className="section__admin--header-top">
          <BackPageBtn />
        </div>
        <div className="section__admin--header-bottom">
          <h5>{title}</h5>
          {events ? (
            <div className="section__admin--header-bottom-events">
              {events.map((event, index) => (
                <Button
                  key={index}
                  type={event.type}
                  styles={event.class}
                  text={event.text}
                  icon={event.icon}
                  action={event.action ? event.action : null}
                  link={event.link ? event.link : null}
                />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default SectionHeaderAdmin;
