import {
  PAYMENT_CREATE_REQUEST,
  PAYMENT_CREATE_SUCCESS,
  PAYMENT_CREATE_FAIL,
  PAYMENT_CREATE_RESET,
  PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_FAIL,
  PAYMENT_STATUS_RESET,
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_RESET,
} from "сonstants/paymentConstants";

export const paymentCreateReducer = (state = { payment: {} }, action) => {
  switch (action.type) {
    case PAYMENT_CREATE_REQUEST:
      return { loading: true };
    case PAYMENT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        payment: action.payload,
      };
    case PAYMENT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_CREATE_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const paymentStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case PAYMENT_STATUS_REQUEST:
      return { loading: true };
    case PAYMENT_STATUS_SUCCESS:
      return {
        loading: false,
        success: true,
        status: action.payload,
      };
    case PAYMENT_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const paymentListReducer = (state = { payments: [] }, action) => {
  switch (action.type) {
    case PAYMENT_LIST_REQUEST:
      return { loading: true };
    case PAYMENT_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        payments: action.payload,
      };
    case PAYMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_LIST_RESET:
      return {};
    default:
      return state;
  }
};
