import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import ym from "react-yandex-metrika";

// MIDDLEWARE
import { getRusMonthDative } from "middleware/dates";

// CONSTANTS
import { LECTION_DETAILS_RESET } from "сonstants/lectionConstants";
import { PAYMENT_CREATE_RESET } from "сonstants/paymentConstants";

// ACTIONS
// Lections
import { listLectionDetails } from "redux/actions/lectionActions";
// Users
import { getUser } from "redux/actions/userActions";
// Subscribe
import {
  subscribeToLection,
  unSubscribeToLection,
  getSubscriptionStatus,
} from "redux/actions/subscribeActions";
// Payment
import { createPayment, getPaymentStatus } from "redux/actions/paymentActions";

// COMPONENTS
import Meta from "components/Meta/Meta";
import BackPageBtn from "components/Buttons/PrevPage";
import Spinner from "components/Loaders/Spinner";
import Label from "components/Label/Label";
import Button from "components/Buttons/Button";

// LOCAL COMPONENTS
import Subtitle from "./components/Subtitle";
import AmountSelector from "./components/AmountSelector";

// CUSTOM HOOKS
import useSubscription from "hooks/useSubscription";
import usePaymentStatus from "hooks/usePaymentStatus";

function LectionSinglePage({ match, history }) {
  const dispatch = useDispatch();

  // REDUX STATE
  const currentUser = useSelector((state) => state.currentUser);
  const { loading: userLoading, userInfo: user } = currentUser;

  // LECTIONS DETAILS
  const lectionDetails = useSelector((state) => state.lections.lectionDetails);
  const { loading, error, lection } = lectionDetails;

  // SUBSCRIBE DETAILS
  const subscribeLection = useSelector(
    (state) => state.subscription.subscribeLection
  );
  const { loading: loadingSubscribe } = subscribeLection;

  // UNSUBSCRIBE DETAILS
  const unsubscribeLection = useSelector(
    (state) => state.subscription.unsubscribeLection
  );
  const { loading: loadingUnSubscribe } = unsubscribeLection;

  // PURCHASE TICKETS
  const paymentLection = useSelector((state) => state.payment.create);
  const { success: successPayment, payment } = paymentLection;

  // LOCAL STATE
  const [visitorsAmount, setVisitorsAmount] = useState(1);

  // Memo subscription
  const subscription = useMemo(
    () => ({
      getCurrentValue: () => dispatch(getSubscriptionStatus(match.params.id)),
    }),
    [dispatch, match.params.id]
  );

  const payments = useMemo(
    () => ({
      getCurrentValue: () => dispatch(getPaymentStatus(match.params.id)),
    }),
    [dispatch, match.params.id]
  );

  // CUSTOM HOOKS
  const isSubscribed = useSubscription(subscription);
  const { isPaid, ticketsAmount } = usePaymentStatus(payments);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  // EFFECT TO GET / CLEAR LECTION DATA
  useEffect(() => {
    dispatch(listLectionDetails(match.params.id));

    return () => {
      dispatch({ type: LECTION_DETAILS_RESET });
    };
  }, [dispatch, match]);

  // EFFECT TO REDIRECT AFTER PAYMENT CLICKED
  useEffect(() => {
    if (successPayment) {
      window.location.href = payment.confirmationUrl;
    }
    return () => {
      dispatch({ type: PAYMENT_CREATE_RESET });
    };
    // eslint-disable-next-line
  }, [dispatch, successPayment, history]);

  // SUBSCRIBE HANDLER
  const handleSubscribe = () => {
    if (!user) {
      history.push("/auth/login");
    } else {
      dispatch(subscribeToLection(match.params.id, visitorsAmount));
      if (process.env.NODE_ENV !== "development") {
        ym("reachGoal", "registr");
      }
    }
  };

  // UNSUBSCRIBE HANDLER
  const handleUnSubscribe = () => {
    dispatch(unSubscribeToLection(match.params.id));
  };

  // TICKET PURCHASE HANDLER
  const handleTicketPurchase = () => {
    if (!user) {
      history.push("/auth/login");
    }
    dispatch(createPayment(match.params.id, visitorsAmount));
  };

  return (
    <>
      <Meta
        title={
          lection && lection.title
            ? `${lection.title} | Лекторий Другие Измерения`
            : "Лекторий Другие Измерения"
        }
      />
      <main className="margined-navbar">
        <section className="section--lectorium-page__single-event">
          <div className="container">
            <div className="content-bar">
              <BackPageBtn />
            </div>
            {loading || userLoading ? (
              <Spinner />
            ) : error ? (
              <h1>{error}</h1>
            ) : (
              <div className="single-event__wrapper">
                <div className="single-event__header">
                  <div className="single-event__header--left">
                    <img src={lection.image} alt="Изображение События" />
                  </div>
                  <div className="single-event__header--right">
                    <div className="single-event__header--content-top">
                      <div className="single-event__header--content-top-tags">
                        <Label
                          styles={"label--medium label--violet"}
                          text={lection.topic}
                        />
                        {isPaid ? (
                          <Label
                            styles={"label--medium label--yellow"}
                            text={
                              ticketsAmount === 1 ? (
                                <span>
                                  У вас куплен
                                  {" " + ticketsAmount + " "}
                                  билет
                                </span>
                              ) : ticketsAmount > 1 && ticketsAmount <= 3 ? (
                                <span>
                                  У вас куплено
                                  {" " + ticketsAmount + " "}
                                  билета
                                </span>
                              ) : (
                                <span>
                                  У вас куплено
                                  {" " + ticketsAmount + " "}
                                  билетов
                                </span>
                              )
                            }
                          />
                        ) : null}
                      </div>
                      <div className="single-event__header--content-top-title">
                        <h1>{lection.title}</h1>
                      </div>
                    </div>
                    <div className="single-event__header--content-middle">
                      <div className="single-event__header--content-middle-date">
                        <Subtitle text="Дата и время" />
                        {lection.date ? lection.date.slice(8, 10) : null}{" "}
                        {lection.date
                          ? getRusMonthDative(
                              Number(lection.date.slice(5, 7))
                            ).toLowerCase()
                          : null}{" "}
                        / {lection.startTime}
                      </div>
                      <div className="single-event__header--content-middle-amount">
                        <>
                          {lection.totalAmount - lection.currentAmount !== 0 ? (
                            <>
                              <Subtitle text="Осталось мест" />
                              {lection.totalAmount - lection.currentAmount}
                            </>
                          ) : null}
                        </>
                      </div>
                      {lection.price > 0 ? (
                        <div className="single-event__header--content-middle-price">
                          <Subtitle text="Стоимость" />
                          {lection.price} ₽{" "}
                        </div>
                      ) : null}
                    </div>
                    <div className="single-event__header--content-bottom">
                      {!lection.isRegisterOpen ? (
                        <span className="single-event__header--warning">
                          Регистрации на это событие нет
                        </span>
                      ) : user && !user.isEmailConfirmed ? (
                        <span className="single-event__header--warning">
                          Проверьте вашу почту для подтверждения Email
                        </span>
                      ) : loadingSubscribe || loadingUnSubscribe ? (
                        <span className="single-event__header--warning">
                          Загрузка...
                        </span>
                      ) : isSubscribed ? (
                        <>
                          <Subtitle text="Вы можете отменить регистрацию" />
                          <Button
                            type="button"
                            styles="btn btn-x1 btn-red-fill"
                            text="Отменить"
                            action={handleUnSubscribe}
                          />
                        </>
                      ) : (
                        <>
                          {lection.totalAmount - lection.currentAmount !== 0 ? (
                            <>
                              <Subtitle text="Регистрация на мероприятие" />
                              <AmountSelector
                                amount={visitorsAmount}
                                changeAmount={setVisitorsAmount}
                                maxValue={3}
                                minValue={1}
                              />
                              <div className="header__content--bottom-subscribe-actions">
                                {!isPaid ? (
                                  <>
                                    <Button
                                      type="button"
                                      styles={`btn btn-x2 ${
                                        lection.isPaid
                                          ? "btn-black-fill"
                                          : "btn-green-fill"
                                      }`}
                                      text="Зарегистрироваться"
                                      action={handleSubscribe}
                                    />
                                    {lection.isPaid ? <p>или</p> : null}
                                  </>
                                ) : null}
                                {lection.isPaid ? (
                                  <Button
                                    type="button"
                                    styles="btn btn-x2 btn-green-fill"
                                    text="Купить билет"
                                    action={handleTicketPurchase}
                                  />
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <h6>К сожалению, места закончились</h6>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="single-event__description">
                  {lection.lecturer ? (
                    <div className="description__lecturer">
                      <Subtitle text="Лектор / Организатор" />
                      <h2>
                        {lection.lecturer
                          .split("/")
                          .filter((item) => item.trim() !== "")
                          .join("/")}
                      </h2>
                    </div>
                  ) : null}
                  <Subtitle text="краткое описание" />
                  <div className="single-event__description--text">
                    {parse(`${lection.description}`)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default LectionSinglePage;
