import React from "react";
import { Helmet } from "react-helmet";

function Meta({ title, description, keywords, ogDescription, ogTitle }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
      <meta name="og:title" content={ogTitle} />
      <meta name="og:description" content={ogDescription} />
    </Helmet>
  );
}

Meta.defaultProps = {
  title: "Книги, кофе и другие измерения — книжный магазин",
  description: "Книжный магазин, кофейня и лекторий в Верхней Пышме",
  keywords:
    "Книги, Верхняя Пышма, Книги Верхняя Пышма, Книжный Пышма, Кофе, Кофейня Верхняя Пышма, Другие книги, drugie knigi, drugie-knigi.ru, Лекторий, Лекторий Екатеринбург, Лекторий Пышма, книги кофе и др измерения, книги кофе другие измерения, книги кофе другие измерения сайт, Другие измерения",
  ogTitle: "Книги, Кофе и Другие Измерения",
  ogDescription: "Книжный магазин, кофейня и лекторий в Верхней Пышме",
};

export default Meta;
