import {
  STAT_USERS_REQUEST,
  STAT_USERS_SUCCESS,
  STAT_USERS_FAIL,
  STAT_LECTIONS_REQUEST,
  STAT_LECTIONS_SUCCESS,
  STAT_LECTIONS_FAIL,
} from "сonstants/statConstants";

// LECTIONS AND WORKERS STATS
export const usersStatsReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case STAT_USERS_REQUEST:
      return { loading: true, ...state };
    case STAT_USERS_SUCCESS:
      return {
        loading: false,
        users: action.payload,
      };
    case STAT_USERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const lectionsStatsReducer = (state = { lections: [] }, action) => {
  switch (action.type) {
    case STAT_LECTIONS_REQUEST:
      return { loading: true, ...state };
    case STAT_LECTIONS_SUCCESS:
      return {
        loading: false,
        lections: action.payload,
      };
    case STAT_LECTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
