import React from "react";
import { Link } from "react-router-dom";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function AdminAnalytic() {
  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Аналитика" />
      <section className="section--admin-actions">
        <div className="container">
          <div className="cards-wrapper">
            <Link to="/admin/analytic/lections" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-projector"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Аналитика Лектория</h6>
            </Link>
            <Link to="/admin/analytic/shop" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-users"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Аналитика Посетителей</h6>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AdminAnalytic;
