import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import { listArchiveLections } from "redux/actions/lectionActions";

// COMPONENTS
import Spinner from "components/Loaders/Spinner";
import CardAdmin from "components/cards/CardAdmin";
import Pagination from "components/Buttons/Pagination";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function LectionsArchive() {
  const dispatch = useDispatch();

  const [searchFields] = useState("title+lecturer");
  const [searchBody, setSearchBody] = useState("");

  // GET LECTIONS ARCHIVE
  const lectionsArchieve = useSelector(
    (state) => state.lections.lectionsArchieve
  );
  const { loading, lections, error } = lectionsArchieve;

  useEffect(() => {
    dispatch(
      listArchiveLections(
        "",
        "",
        20,
        "-dateWithTime",
        "",
        searchFields,
        searchBody
      )
    );
  }, [dispatch, searchFields, searchBody]);

  const handleArchivePagination = (page) => {
    dispatch(listArchiveLections("", "", 20, "-dateWithTime", page));
    window.scrollTo(0, 0);
  };

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Архив мероприятий" />
      <section className="section--admin__lections--search">
        <div className="container">
          <div className="input__search">
            <i className="far fa-search"></i>
            <input
              type="text"
              placeholder="Поиск мероприятия"
              value={searchBody}
              onChange={(e) => setSearchBody(e.target.value)}
            />
          </div>
        </div>
      </section>
      <section className="section--admin__lections--archive">
        <div className="container">
          {loading || !lections.data ? (
            <Spinner />
          ) : error ? (
            <h6>{error}</h6>
          ) : (
            <>
              <div className="cards-wrapper">
                {lections.data.map((lection) => (
                  <CardAdmin
                    key={lection._id}
                    data={lection}
                    events={[
                      {
                        type: "link",
                        class: "btn-x2 btn btn-white btn-green-fill",
                        text: "Перейти",
                        link: "/admin/lections/archive/replace-id",
                      },
                    ]}
                    badges={true}
                  />
                ))}
              </div>
              <Pagination
                pages={lections.pagesTotal ? lections.pagesTotal : []}
                currentPage={lections.page ? lections.page : 1}
                action={handleArchivePagination}
              />
            </>
          )}
        </div>
      </section>
    </main>
  );
}

export default LectionsArchive;
