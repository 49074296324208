import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// MIDDLEWARE
import { getRusMonthDative } from "middleware/dates";

// ACTIONS
import {
  listLectionDetails,
  listLectionVisitors,
  checkVisitor,
  deleteVisitor,
} from "redux/actions/lectionActions";

// CONSTANTS
// import { LECTION_VISITORS_RESET } from "сonstants/lectionConstants";

// COMPONENTS
import Spinner from "../../../components/Loaders/Spinner";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function Visitors({ match, history }) {
  const dispatch = useDispatch();

  const lectionId = match.params.id;

  const [isVisitorDeleted, setIsVisitorDeleted] = useState(false);

  // LECTIONS DETAILS
  const lectionDetails = useSelector((state) => state.lections.lectionDetails);
  const { loading: lectionLoading, lection } = lectionDetails;

  // GET VISITORS FROM STATE
  const lectionVisitors = useSelector(
    (state) => state.lections.lectionVisitors
  );
  const { loading, subscriptions, payments } = lectionVisitors;

  // GET VISITOR SUCCESS CHECK FROM STATE
  const lectionVisitorCheck = useSelector(
    (state) => state.lections.lectionVisitorCheck
  );
  const { success: successVisitorCheck } = lectionVisitorCheck;

  useEffect(() => {
    dispatch(listLectionDetails(lectionId));
    dispatch(listLectionVisitors(lectionId));

    if (isVisitorDeleted) {
      setIsVisitorDeleted(false);
    }

    // return () => {
    //   dispatch({ type: LECTION_VISITORS_RESET });
    // };
  }, [dispatch, history, lectionId, successVisitorCheck, isVisitorDeleted]);

  const handleVisitorCheck = (subscriptionId, visitorId) => {
    dispatch(checkVisitor(lectionId, subscriptionId, visitorId));
  };

  const handleVisitorDelete = (visitorId) => {
    if (window.confirm("Вы уверены?")) {
      dispatch(deleteVisitor(lectionId, visitorId));
      setIsVisitorDeleted(true);
    }
  };

  return (
    <main className="margined-navbar">
      {lectionLoading || !lection.date ? (
        <Spinner />
      ) : (
        <SectionHeaderAdmin
          title={`${lection.title} / ${lection.topic} / ${lection.date.slice(
            8,
            10
          )} ${getRusMonthDative(
            Number(lection.date.slice(5, 7))
          ).toLowerCase()}`}
        />
      )}
      <section className="section--admin__lection--visitors">
        <div className="container">
          {loading ? (
            <Spinner />
          ) : (
            <div className="visitors-wrapper">
              <h6>Оплаченные</h6>
              <div className="cards-wrapper">
                {payments.length > 0 ? (
                  payments.map((visitor) => (
                    <div className="card-visitor-admin" key={visitor._id}>
                      <div className="card-content">
                        <p className="card-title">{visitor.user.name}</p>
                        <p className="card-email">{visitor.user.email}</p>
                        <p className="card-phone">{visitor.user.phone}</p>
                        <p className="card-phone">
                          Дата:{" "}
                          {`${visitor.createdAt.slice(
                            8,
                            10
                          )} ${getRusMonthDative(
                            Number(visitor.createdAt.slice(5, 7))
                          )} ${visitor.createdAt.slice(0, 4)}`}
                        </p>
                        <p className="card-phone">
                          Количество: {visitor.ticketsAmount}
                        </p>
                        <p className="card-phone">
                          ID платежа: {visitor.idempotenceKey}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Нет оплаченных регистраций</p>
                )}
              </div>
              <h6>Простые регистрации</h6>
              <div className="cards-wrapper">
                {subscriptions.length > 0 ? (
                  subscriptions.map((visitor) => (
                    <div className="card-visitor-admin" key={visitor.user._id}>
                      <button
                        className="card-delete-button"
                        onClick={() => handleVisitorDelete(visitor._id)}
                      >
                        <i className="fal fa-trash-alt"></i>
                      </button>
                      <div className="card-content">
                        <p className="card-title">{visitor.user.name}</p>
                        <p className="card-email">{visitor.user.email}</p>
                        <p className="card-phone">{visitor.user.phone}</p>
                        <p className="card-phone">
                          Дата:{" "}
                          {`${visitor.subscribedAt.slice(
                            8,
                            10
                          )} ${getRusMonthDative(
                            Number(visitor.subscribedAt.slice(5, 7))
                          )} ${visitor.subscribedAt.slice(0, 4)}`}
                        </p>
                        {visitor.visitors.length === 0 ? (
                          <p className="card-amount">
                            Записано: {visitor.amount}
                          </p>
                        ) : (
                          <p className="card-amount">
                            Пришло: {visitor.totalAmount}
                          </p>
                        )}
                        {visitor.visitors.map((addVisitor) => (
                          <button
                            key={addVisitor.id}
                            className={
                              addVisitor.isChecked
                                ? "card-check-visitor-btn visitor-checked"
                                : "card-check-visitor-btn"
                            }
                            onClick={() =>
                              handleVisitorCheck(visitor._id, addVisitor.id)
                            }
                          >
                            <i className="fal fa-check-circle"></i>
                            <span>Посетитель {addVisitor.id}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Нет регистраций</p>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}

export default Visitors;
