import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkDateLength } from "middleware/dates";

// ACTIONS
import { listVisitors, createVisitor } from "redux/actions/visitorActions";

// COMPONENTS
import Spinner from "components/Loaders/Spinner";

// LOCAL COMPONENTS
import SectionStatAdmin from "../components/SectionStatAdmin";
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function VisitorsTracker() {
  const dispatch = useDispatch();

  // CURRENT DATE
  const newDate = new Date();
  const currentDate = `${newDate.getFullYear()}-${checkDateLength(
    newDate.getMonth() + 1
  )}-${checkDateLength(newDate.getDate())}`;

  // CREATE VISITOR
  const visitorCreate = useSelector((state) => state.visitorCreate);
  const { success: successCreate } = visitorCreate;

  // GET VISITORS
  const visitorsList = useSelector((state) => state.visitorsList);
  const { loading, error, visitors } = visitorsList;

  useEffect(() => {
    dispatch(listVisitors(currentDate, currentDate));
  }, [dispatch, successCreate, currentDate]);

  // HANDLE BUTTONS CLICKS
  const handleVisitorClick = async (title, visitorType, actionType) => {
    dispatch(createVisitor(title, actionType, visitorType));
  };

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Трекер посетителей" />
      {loading ? (
        <Spinner />
      ) : error ? (
        <h2>{error}</h2>
      ) : (
        <SectionStatAdmin
          data={[
            {
              id: 1,
              title: "Посетители",
              stats: [
                {
                  id: 1,
                  title: "Всего сегодня",
                  data: !visitors
                    ? 0
                    : visitors.data.filter(
                        (visitor) =>
                          visitor.actionType === "visit" &&
                          visitor.date.toString().slice(0, 10) === currentDate
                      ).length,
                },
              ],
            },
            {
              id: 2,
              title: "Продажи",
              stats: [
                {
                  id: 1,
                  title: "Всего сегодня",
                  data: !visitors
                    ? 0
                    : visitors.data.filter(
                        (visitor) =>
                          visitor.actionType === "purchase" &&
                          visitor.date.toString().slice(0, 10) === currentDate
                      ).length,
                },
              ],
            },
          ]}
        />
      )}
      <section className="section--admin-visitors-tracker">
        <div className="container">
          <div className="wrapper">
            <div className="action-and-stat-column">
              <div className="grid-container">
                <button
                  className="btn-submit-visitor"
                  onClick={() => handleVisitorClick("Мужчина", "male", "visit")}
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                  </div>
                  <h6 className="title">Мужчина</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Женщина", "female", "visit")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                  </div>
                  <h6 className="title">Женщина</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() => handleVisitorClick("Семья", "family", "visit")}
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                    <i className="fal fa-child"></i>
                    <i className="fal fa-male"></i>
                  </div>
                  <h6 className="title">Семья</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Мама / Ребёнок", "momchild", "visit")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Мама / Ребёнок</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Папа / Ребёнок", "dadchild", "visit")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Папа / Ребёнок</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Подросток", "teenager", "visit")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-running"></i>
                  </div>
                  <h6 className="title">Подросток</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Ребенок", "child", "visit")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Ребёнок</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Компания подростков",
                      "teen_company",
                      "visit"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-running"></i>
                    <i className="fal fa-running"></i>
                  </div>
                  <h6 className="title">Компания подр.</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Пожилой мужчина", "elder_male", "visit")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                  </div>
                  <h6 className="title">Пожилой муж.</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Пожилая женщина",
                      "elder_female",
                      "visit"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                  </div>
                  <h6 className="title">Пожилая жен</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Бабушка / Внук",
                      "grandma_child",
                      "visit"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Бабушка / Внук</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Дедушка / Внук",
                      "grandpa_child",
                      "visit"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Дедушка / Внук</h6>
                </button>
              </div>
            </div>
            <div className="action-and-stat-column">
              <div className="grid-container">
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Мужчина", "male", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                  </div>
                  <h6 className="title">Мужчина</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Женщина", "female", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                  </div>
                  <h6 className="title">Женщина</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Семья", "family", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                    <i className="fal fa-child"></i>
                    <i className="fal fa-male"></i>
                  </div>
                  <h6 className="title">Семья</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Мама / Ребенок", "momchild", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Мама / Ребёнок</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Папа / Ребенок", "dadchild", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Папа / Ребёнок</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Подросток", "teenager", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-running"></i>
                  </div>
                  <h6 className="title">Подросток</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick("Ребенок", "child", "purchase")
                  }
                >
                  <div className="icon">
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Ребёнок</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Компания подростков",
                      "teen_company",
                      "purchase"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-running"></i>
                    <i className="fal fa-running"></i>
                  </div>
                  <h6 className="title">Компания подр.</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Пожилой мужчина",
                      "elder_male",
                      "purchase"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                  </div>
                  <h6 className="title">Пожилой муж.</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Пожилая женщина",
                      "elder_female",
                      "purchase"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                  </div>
                  <h6 className="title">Пожилая жен</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Бабушка / Внук",
                      "grandma_child",
                      "purchase"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-female"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Бабушка / Внук</h6>
                </button>
                <button
                  className="btn-submit-visitor"
                  onClick={() =>
                    handleVisitorClick(
                      "Дедушка / Внук",
                      "grandpa_child",
                      "purchase"
                    )
                  }
                >
                  <div className="icon">
                    <i className="fal fa-male"></i>
                    <i className="fal fa-child"></i>
                  </div>
                  <h6 className="title">Дедушка / Внук</h6>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default VisitorsTracker;
