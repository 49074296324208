import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobSouvenirDesigner() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Дизайнер сувениров</h4>
          <div className="content">
            <p>
              Мы активно развиваем направление создания сувенирной продукции на
              культурную тематику для продажи ее как в нашем магазине, так и у
              наших партнеров, среди которых есть музеи и торговые центры. Мы
              хотим привлечь как можно больше дизайнеров, умеющих придумывать
              интересные товары и упаковки, которым мы будем готовы платить за
              их разработки. Наша задача — создать базу классных дизайнеров,
              заинтересованных в небольших подработках, но со временем,
              возможно, вы нам так понравитесь, что мы позовем вас к себе в
              штат.
            </p>
            <p>
              Если вам интересно нарисовать для нас афишу или придумать сувенир,
              присылайте свои портфолио и желаемую стоимость макета, иллюстрации
              или полного пакета. Отвечать будем тем, кто нам понравится.
              Отсутствие портфолио в отклике — моментальный отказ, поэтому лучше
              акцентируйте на нем внимание: вы ж, все-таки, дизайнер.
            </p>
            <p>
              Сейчас вам достаточно написать на электронную почту
              <a href="mailto: labor@drugie-knigi.ru">
                {" "}
                labor@drugie-knigi.ru
              </a>{" "}
              с темой письма «Кандидат в дизайнеры». В ответ мы отправим вам
              небольшую анкету. Если от нас не будет ответа больше трех дней,
              проверьте папку «Спам», а потом продублируйте ваше письмо.
            </p>
            <p>
              Если и это не помогло, напишите нам в соцсетях или позвоните по
              телефону <a href="tel:+79122000199">+79122000199</a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobSouvenirDesigner;
