import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import { listWorkerDetails, updateWorker } from "redux/actions/workerActions";

// CONSTANTS
import { API_PROXY } from "сonstants/apiConstants";
import { WORKER_UPDATE_RESET } from "сonstants/workerConstants";

// COMPONENT
import InputWrapper from "components/Inputs/InputWrapper";
import InputStandart from "components/Inputs/InputStandart";
import Editor from "components/Editor/Editor";
import Spinner from "components/Loaders/Spinner";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function EditWorker({ match, history }) {
  const workerId = match.params.id;

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [topicForFilters, setTopicForFilters] = useState("");
  const [date, setDate] = useState("");
  const [dateString, setDateString] = useState("");
  const [time, setTime] = useState("");
  const [workerCategory, setWorkerCategory] = useState("");
  const [image, setImage] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [editorDescription, setEditorDescription] = useState();
  const [uploading, setUploading] = useState(false);

  const workerDetails = useSelector((state) => state.workers.workerDetails);
  const { loading, error, worker } = workerDetails;

  const workerUpdate = useSelector((state) => state.workers.workerUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = workerUpdate;

  useEffect(() => {
    if (successUpdate) {
      history.push("/admin/workers");
      dispatch({ type: WORKER_UPDATE_RESET });
    } else {
      if (!worker.name || worker._id !== workerId) {
        dispatch(listWorkerDetails(workerId));
      } else {
        setName(worker.name);
        setTopic(worker.topic);
        setTopicForFilters(worker.topicForFilters);
        setDate(worker.date);
        setDateString(worker.dateString);
        setTime(worker.time);
        setWorkerCategory(worker.workerCategory);
        setImage(worker.image);
        setShortDescription(worker.shortDescription);
      }
    }
  }, [dispatch, history, workerId, worker, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `${API_PROXY}/upload`,
        formData,
        config
      );
      // Remove absolute path
      const imagePathArray = data.split("/");
      const formattedImageSrc = `/${
        imagePathArray[imagePathArray.length - 2]
      }/${imagePathArray[imagePathArray.length - 1]}`;

      setImage(formattedImageSrc);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(
      updateWorker({
        _id: workerId,
        name,
        topic,
        topicForFilters,
        date,
        dateString,
        time,
        workerCategory,
        image,
        shortDescription,
        description: editorDescription,
      })
    );
  };

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Редактирование сотрудника" />
      <section className="section__admin--worker-edit">
        <div className="container">
          {loadingUpdate && <Spinner />}
          {errorUpdate && <h6>{errorUpdate}</h6>}
          {loading ? (
            <Spinner />
          ) : error ? (
            <h6>{error}</h6>
          ) : (
            <form className="workers-management-form" onSubmit={submitHandler}>
              <div className="grid-container">
                <InputWrapper label="Заголовок">
                  <InputStandart
                    type="text"
                    placeholder="Заголовок"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Отдел книжного">
                  <InputStandart
                    type="text"
                    placeholder="физика"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    subtext="Не заполняется для сотрудников кофейни"
                  />
                </InputWrapper>
                <InputWrapper label="Категория на англ (через -)">
                  <InputStandart
                    type="text"
                    placeholder="physics"
                    value={topicForFilters}
                    onChange={(e) => setTopicForFilters(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Дата (гг-мм-дд / 2020-11-01)">
                  <InputStandart
                    type="string"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    subtext="Не заполняется для сотрудников кофейни"
                  />
                </InputWrapper>
                <InputWrapper label="Дата (29 сен) через запятую">
                  <InputStandart
                    type="text"
                    placeholder="29 сен"
                    value={dateString}
                    onChange={(e) => setDateString(e.target.value)}
                    subtext="Не заполняется для сотрудников кофейни"
                  />
                </InputWrapper>
                <InputWrapper label="Время">
                  <InputStandart
                    type="text"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    subtext="Не заполняется для сотрудников кофейни"
                  />
                </InputWrapper>
                <InputWrapper label="Отдел">
                  <select
                    className="input__select"
                    value={workerCategory}
                    onChange={(e) => setWorkerCategory(e.target.value)}
                  >
                    <option value="coffee">Кофейня</option>
                    <option value="bookstore">Книжный</option>
                  </select>
                </InputWrapper>
                <InputWrapper label="Выберите Файл">
                  <InputStandart type="file" onChange={uploadFileHandler} />
                  {uploading && <h6>Loading...</h6>}
                </InputWrapper>
                <InputWrapper label="Изображение">
                  <InputStandart
                    type="text"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Короткое описание">
                  <InputStandart
                    type="text"
                    placeholder="Короткое описание"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                  />
                </InputWrapper>
                <div className="textarea-block">
                  <label htmlFor="">Описание</label>
                  <Editor
                    data={worker.description}
                    setEditorDescription={setEditorDescription}
                  />
                </div>
              </div>
              <div className="edit-buttons">
                <button className="btn-x2 btn-green-fill" type="submit">
                  Редактировать
                </button>
              </div>
            </form>
          )}
        </div>
      </section>
    </main>
  );
}

export default EditWorker;
