import {
  VISITORS_GET_REQUEST,
  VISITORS_GET_SUCCESS,
  VISITORS_GET_FAIL,
  VISITORS_GET_RESET,
  VISITOR_CREATE_REQUEST,
  VISITOR_CREATE_SUCCESS,
  VISITOR_CREATE_FAIL,
  VISITOR_CREATE_RESET,
} from "сonstants/visitorsConstants";

// GET VISITORS (ADMIN)
export const visitorsListReducer = (state = {}, action) => {
  switch (action.type) {
    case VISITORS_GET_REQUEST:
      return { loading: true, ...state };
    case VISITORS_GET_SUCCESS:
      return {
        loading: false,
        visitors: action.payload,
      };
    case VISITORS_GET_FAIL:
      return { loading: false, error: action.payload };
    case VISITORS_GET_RESET:
      return {};
    default:
      return state;
  }
};

// ADD SHOP VISITOR (ADMIN)
export const visitorCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case VISITOR_CREATE_REQUEST:
      return { loading: true };
    case VISITOR_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        visitor: action.payload.data,
      };
    case VISITOR_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case VISITOR_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
