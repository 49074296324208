// CREATE PAYMENT
export const PAYMENT_CREATE_REQUEST = "PAYMENT_CREATE_REQUEST";
export const PAYMENT_CREATE_SUCCESS = "PAYMENT_CREATE_SUCCESS";
export const PAYMENT_CREATE_FAIL = "PAYMENT_CREATE_FAIL";
export const PAYMENT_CREATE_RESET = "PAYMENT_CREATE_RESET";

export const PAYMENT_STATUS_REQUEST = "PAYMENT_STATUS_REQUEST";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAIL = "PAYMENT_STATUS_FAIL";
export const PAYMENT_STATUS_RESET = "PAYMENT_STATUS_RESET";

export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";
export const PAYMENT_LIST_RESET = "PAYMENT_LIST_RESET";
