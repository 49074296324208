import React from "react";
import { getRusMonthDative } from "../../middleware/dates";

// COMPONENTS
import Button from "../Buttons/Button";
import Label from "../Label/Label";

function CardAdmin({ data, events, badges }) {
  return (
    <div className="card__admin--default" key={data._id}>
      <div className="labels__wrapper">
        {data.price > 0 ? (
          <Label styles={"label--small label--red"} text={"Платное"} />
        ) : null}
        {data.isPaid ? (
          <Label styles={"label--small label--yellow"} text={"Онлайн билеты"} />
        ) : null}
        {data.lecturer ? (
          <Label
            styles={"label--small label--dimgray"}
            text={data.lecturer.split("/")[0]}
          />
        ) : null}
        {data.isFinished ? (
          <Label styles={"label--violet-light"} text={"Завершено"} />
        ) : null}
      </div>
      <div
        className={`card__admin--default-info ${
          badges ? "card__admin--badges" : null
        }`}
      >
        <div id="title" className="info__block">
          <div className="info__block--title">
            {data.title ? "Заголовок" : "Имя / Фамилия"}
          </div>
          <div className="info__block--content">{data.title || data.name}</div>
        </div>
        {data.totalAmount || data.startTime ? (
          <>
            <div className="info__block">
              <div className="info__block--title">Дата</div>
              <div className="info__block--content">
                {data.date ? data.date.slice(8, 10) : null}{" "}
                {data.date
                  ? getRusMonthDative(Number(data.date.slice(5, 7)))
                      .toLowerCase()
                      .slice(0, 3)
                  : null}{" "}
              </div>
            </div>
            <div className="info__block">
              <div className="info__block--title">Время</div>
              <div className="info__block--content">{data.startTime}</div>
            </div>
            <div className="info__block">
              <div className="info__block--title"> регистраций</div>
              <div className="info__block--content">
                {data.currentAmount} / {data.totalAmount}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="card__admin--actions">
        {events.map((event, index) => (
          <Button
            key={index}
            type={event.type}
            styles={event.class}
            text={event.text}
            icon={event.icon}
            action={event.action ? () => event.action(data._id) : null}
            link={
              event.link ? event.link.replace(/replace-id/g, data._id) : null
            }
          />
        ))}
      </div>
    </div>
  );
}

export default CardAdmin;
