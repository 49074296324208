import React, { useEffect } from "react";

function Pagination({ pages, currentPage, action }) {
  // Find index of active button and append it active class
  useEffect(() => {
    const paginationButtons = Array.from(
      document.querySelectorAll(".pagination button")
    );
    const activeButton = paginationButtons.filter(
      (button) => Number(button.outerText) === currentPage
    )[0];
    activeButton.classList.add("pagination--active");
    return () => activeButton.classList.remove("pagination--active");
  }, [currentPage]);
  return (
    <div className="pagination">
      {currentPage < 2 ? (
        <>
          <button onClick={() => action(currentPage)}>{currentPage}</button>
          <button onClick={() => action(currentPage + 1)}>
            {currentPage + 1}
          </button>
          <button onClick={() => action(currentPage + 2)}>
            {currentPage + 2}
          </button>
          {currentPage !== pages && currentPage + 2 !== pages ? (
            <button onClick={() => action(pages)}>{pages}</button>
          ) : null}
        </>
      ) : (
        <>
          <button onClick={() => action(1)}>1</button>
          {currentPage === pages ? (
            <button onClick={() => action(currentPage - 2)}>
              {currentPage - 2}
            </button>
          ) : null}
          {currentPage > 2 ? (
            <button onClick={() => action(currentPage - 1)}>
              {currentPage - 1}
            </button>
          ) : null}
          <button onClick={() => action(currentPage)}>{currentPage}</button>
          {currentPage < pages ? (
            <button onClick={() => action(currentPage + 1)}>
              {currentPage + 1}
            </button>
          ) : null}
          {currentPage !== pages - 1 && currentPage < pages ? (
            <button onClick={() => action(pages)}>{pages}</button>
          ) : null}
        </>
      )}
    </div>
  );
}

export default Pagination;
