import axios from "axios";

import { API_PROXY } from "сonstants/apiConstants";

import {
  WORKERS_LIST_REQUEST,
  WORKERS_LIST_SUCCESS,
  WORKERS_LIST_FAIL,
  WORKER_DETAILS_REQUEST,
  WORKER_DETAILS_SUCCESS,
  WORKER_DETAILS_FAIL,
  WORKER_CREATE_REQUEST,
  WORKER_CREATE_SUCCESS,
  WORKER_CREATE_FAIL,
  WORKER_UPDATE_REQUEST,
  WORKER_UPDATE_SUCCESS,
  WORKER_UPDATE_FAIL,
  WORKER_DELETE_REQUEST,
  WORKER_DELETE_SUCCESS,
  WORKER_DELETE_FAIL,
} from "сonstants/workerConstants";

// ALL CONSULTANTS
export const listWorkers = (category, date, filter) => async (dispatch) => {
  try {
    dispatch({ type: WORKERS_LIST_REQUEST });

    // API REQUESTS
    const workers = await axios.get(
      `${API_PROXY}/workers?category=${category}&filter=${filter}&date=${date}`
    );
    const filters = await axios.get(`${API_PROXY}/workers/filters/all`);

    dispatch({
      type: WORKERS_LIST_SUCCESS,
      payload: { workers, filters },
    });
  } catch (error) {
    dispatch({
      type: WORKERS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// SINGLE WORKER
export const listWorkerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: WORKER_DETAILS_REQUEST });
    const { data } = await axios.get(`${API_PROXY}/workers/${id}`);
    dispatch({
      type: WORKER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CREATE WORKER (ADMIN)
export const createWorker = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WORKER_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    const { data } = await axios.post(`${API_PROXY}/workers/add`, {}, config);

    dispatch({
      type: WORKER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKER_CREATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// UPDATE WORKER (ADMIN)
export const updateWorker = (worker) => async (dispatch, getState) => {
  try {
    dispatch({ type: WORKER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    const { data } = await axios.put(
      `${API_PROXY}/workers/edit/${worker._id}`,
      worker,
      config
    );

    dispatch({
      type: WORKER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WORKER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// DELETE WORKER (ADMIN)
export const deleteWorker = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: WORKER_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    await axios.delete(`${API_PROXY}/workers/delete/${id}`, config);

    dispatch({
      type: WORKER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: WORKER_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
