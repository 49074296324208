import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import {
  listWorkers,
  createWorker,
  deleteWorker,
} from "redux/actions/workerActions";

// CONSTANTS
import {
  WORKER_CREATE_RESET,
  WORKER_DETAILS_RESET,
} from "сonstants/workerConstants";

// COMPONENTS
import CardAdmin from "components/cards/CardAdmin";
import Spinner from "components/Loaders/Spinner";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function AdminConsultants({ history }) {
  const dispatch = useDispatch();

  // GET WORKERS
  const workersList = useSelector((state) => state.workers.workersList);
  const { loading, error, workers } = workersList;

  // CREATE WORKER
  const workerCreate = useSelector((state) => state.workers.workerCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    worker: createdWorker,
  } = workerCreate;

  // DELETE WORKER
  const workerDelete = useSelector((state) => state.workers.workerDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = workerDelete;

  useEffect(() => {
    if (successCreate) {
      history.push(`/admin/workers/${createdWorker._id}/edit`);
      dispatch({ type: WORKER_CREATE_RESET });
    } else {
      dispatch(listWorkers("", "", ""));
      dispatch({ type: WORKER_DETAILS_RESET });
    }
  }, [dispatch, history, successCreate, createdWorker, successDelete]);

  // CREATE WORKER
  const createWorkerHandler = () => {
    dispatch(createWorker());
  };

  // DELETE WORKER
  const deleteWorkerHandler = (id) => {
    console.log(id);
    if (window.confirm("Вы уверены")) {
      dispatch(deleteWorker(id));
    }
  };

  const cardEvents = [
    {
      type: "link",
      class: "btn-x2 btn btn-white btn-black-fill",
      text: "Редактировать",
      link: "/admin/workers/replace-id/edit",
    },
    {
      type: "button",
      class: "btn-x2 btn btn-white btn-red-fill",
      text: "Удалить",
      action: deleteWorkerHandler,
    },
  ];

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin
        title="Консультанты"
        events={[
          {
            type: "button",
            class: "btn btn-x1 btn-icon btn-black",
            text: "Создать",
            icon: "fal fa-plus",
            action: createWorkerHandler,
          },
        ]}
      />
      <section className="section--admin-workers">
        <div className="container">
          {loadingCreate || loadingDelete ? <Spinner /> : null}
          {errorCreate || errorDelete ? (
            <h6>{errorCreate || errorDelete}</h6>
          ) : null}
          <div className="cards-wrapper">
            {loading || !workers.data ? (
              <Spinner />
            ) : error ? (
              <h1>{error}</h1>
            ) : (
              workers.data.map((worker) =>
                worker.workerCategory === "bookstore" ? (
                  <CardAdmin
                    key={worker._id}
                    data={worker}
                    events={cardEvents}
                    badges={false}
                  />
                ) : null
              )
            )}
          </div>
          <h4>Кофейня</h4>
          <div className="cards-wrapper">
            {loading || !workers.data ? (
              <Spinner />
            ) : error ? (
              <h1>{error}</h1>
            ) : (
              workers.data.map((worker) =>
                worker.workerCategory === "coffee" ? (
                  <CardAdmin
                    key={worker._id}
                    data={worker}
                    events={cardEvents}
                    badges={false}
                  />
                ) : null
              )
            )}
          </div>
        </div>
      </section>
    </main>
  );
}

export default AdminConsultants;
