import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

// MIDDLEWARE
import { getRusMonthDative } from "middleware/dates";

// CONSTANTS
import { LECTION_SINGLE_ARCHIVE_RESET } from "сonstants/lectionConstants";

// ACTIONS
import {
  listArchiveLectionDetails,
  transferArchiveLection,
  copyArchiveLection,
} from "redux/actions/lectionActions";

// CONSTANTS
import { LECTION_ARCHIVE_TRANSFER_RESET } from "сonstants/lectionConstants";

// COMPONENTS
import Meta from "components/Meta/Meta";
import BackPageBtn from "components/Buttons/PrevPage";
import Spinner from "components/Loaders/Spinner";
import Label from "components/Label/Label";

function ArchiveSingleLection({ history, match }) {
  const dispatch = useDispatch();

  // LECTIONS DETAILS FROM STATE
  const lectionArchiveDetails = useSelector(
    (state) => state.lections.lectionArchiveDetails
  );
  const { loading, error, lection } = lectionArchiveDetails;

  const lectionTransfer = useSelector(
    (state) => state.lections.lectionTransfer
  );
  const { success: successTransfer } = lectionTransfer;

  useEffect(() => {
    if (successTransfer) {
      history.push("/admin/lections");
      dispatch({ type: LECTION_ARCHIVE_TRANSFER_RESET });
    } else {
      dispatch(listArchiveLectionDetails(match.params.id));
    }

    return () => {
      dispatch({ type: LECTION_SINGLE_ARCHIVE_RESET });
    };
  }, [dispatch, successTransfer, history, match]);

  const handleArhiveLectionCopy = () => {
    if (window.confirm("Копировать лекцию?")) {
      dispatch(copyArchiveLection(match.params.id));
    }
  };

  const handleArchiveLectionMove = () => {
    if (window.confirm("Переместить лекцию?")) {
      dispatch(transferArchiveLection(match.params.id));
    }
  };

  return (
    <>
      <Meta
        title={
          lection && lection.title
            ? `${lection.title} | Лекторий Другие Измерения`
            : "Лекторий Другие Измерения"
        }
      />
      <main className="margined-navbar">
        <section className="section--lectorium-page__single-event">
          <div className="container">
            <div className="content-bar">
              <BackPageBtn />
            </div>
            {loading ? (
              <Spinner />
            ) : error ? (
              <h1>{error}</h1>
            ) : (
              <div className="single-event__wrapper">
                <div className="single-event__header">
                  <div className="single-event__header--left">
                    <img src={lection.image} alt="Изображение События" />
                  </div>
                  <div className="single-event__header--right">
                    <div className="single-event__header--content-top">
                      <div className="single-event__header--content-top-tags">
                        <Label
                          styles={"label--medium label--violet"}
                          text={lection.topic}
                        />
                      </div>
                      <div className="single-event__header--content-top-title">
                        <h1>{lection.title}</h1>
                      </div>
                    </div>
                    <div className="single-event__header--content-middle">
                      <div className="single-event__header--content-middle-date">
                        <p className="single-event__header--subtitle">
                          Дата и время
                        </p>
                        {lection.date ? lection.date.slice(8, 10) : null}{" "}
                        {lection.date
                          ? getRusMonthDative(
                              Number(lection.date.slice(5, 7))
                            ).toLowerCase()
                          : null}{" "}
                        / {lection.startTime}
                      </div>
                      <div className="single-event__header--content-middle-amount">
                        <p className="single-event__header--subtitle">
                          кол-во регистраций
                        </p>
                        {lection.currentAmount}
                      </div>
                      <div className="single-event__header--content-middle-amount">
                        <p className="single-event__header--subtitle">
                          кол-во пришедших
                        </p>
                        {lection.totalVisitors}
                      </div>
                      {lection.price > 0 ? (
                        <div className="single-event__header--content-middle-price">
                          <p className="single-event__header--subtitle">
                            Стоимость
                          </p>
                          {lection.price} ₽
                        </div>
                      ) : null}
                    </div>
                    <div className="single-event__header--content-bottom">
                      <div className="header__content--bottom-subscribe-actions">
                        <button
                          className="btn btn-x2 btn-green-fill"
                          onClick={handleArhiveLectionCopy}
                        >
                          Копировать
                        </button>
                        <button
                          className="btn btn-x2 btn-black-fill ml-1"
                          onClick={handleArchiveLectionMove}
                        >
                          Перенести
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-event__description">
                  {lection.lecturer ? (
                    <div className="description__lecturer">
                      <p className="single-event__header--subtitle">
                        Лектор / Организатор
                      </p>
                      <h2>
                        {lection.lecturer
                          .split("/")
                          .filter((item) => item.trim() !== "")
                          .join("/")}
                      </h2>
                    </div>
                  ) : null}
                  <p className="single-event__header--subtitle">
                    краткое описание
                  </p>
                  <div className="single-event__description--text">
                    {parse(`${lection.description}`)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default ArchiveSingleLection;
