import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import {
  listLections,
  deleteLection,
  createLection,
} from "redux/actions/lectionActions";

// CONSTANTS
import { LECTION_CREATE_RESET } from "сonstants/lectionConstants";

// COMPONENTS
import Spinner from "components/Loaders/Spinner";
import CardAdmin from "components/cards/CardAdmin";

// LOCAL COMPONENETS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function AdminLections({ history }) {
  const dispatch = useDispatch();

  // GET LECTIONS
  const lectionsList = useSelector((state) => state.lections.lectionsList);
  const { loading, error, lections } = lectionsList;

  // DELETE LECTION
  const lectionDelete = useSelector((state) => state.lections.lectionDelete);
  const { success: successDelete } = lectionDelete;

  // CREATE LECTION
  const lectionCreate = useSelector((state) => state.lections.lectionCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    lection: createdLection,
  } = lectionCreate;

  useEffect(() => {
    if (successCreate) {
      history.push(`/admin/lections/${createdLection._id}/edit`);
      dispatch({ type: LECTION_CREATE_RESET });
    } else {
      dispatch(listLections("", "", ""));
    }
  }, [dispatch, history, successCreate, successDelete, createdLection]);

  // CREATE LECTION
  const createLectionHandler = () => {
    dispatch(createLection());
  };

  // DELETE LECTION
  const deleteLectionHandler = (id) => {
    if (window.confirm("Вы уверены")) {
      dispatch(deleteLection(id));
    }
  };

  const headerEvents = [
    {
      type: "link",
      class: "btn btn-x2 btn-icon btn--gray",
      text: "Билеты",
      icon: "fal fa-ticket",
      link: "/admin/lections/payments/all",
    },
    {
      type: "link",
      class: "btn btn-x2 btn-icon btn--gray",
      text: "Архив",
      icon: "fal fa-archive",
      link: "/admin/lections/archive/all",
    },
    {
      type: "button",
      class: "btn btn-x2 btn-icon btn--gray",
      text: "Создать",
      icon: "fal fa-plus",
      action: createLectionHandler,
    },
  ];

  const cardEvents = [
    {
      type: "link",
      class: "btn-x2 btn btn-black-fill",
      text: "Редактировать",
      link: "/admin/lections/replace-id/edit",
    },
    {
      type: "link",
      class: "btn-x2 btn btn-green-fill",
      text: "Перейти",
      link: "/admin/lections/replace-id",
    },
    {
      type: "button",
      class: "btn-x2 btn btn-red-fill",
      text: "Удалить",
      action: deleteLectionHandler,
    },
  ];

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Управление лекторием" events={headerEvents} />
      <section className="section__admin--lections-list">
        <div className="container">
          {loadingCreate && <Spinner />}
          {errorCreate && <h6>{errorCreate}</h6>}
          {loading ? (
            <Spinner />
          ) : error ? (
            <h6>{error}</h6>
          ) : (
            <div className="cards-wrapper">
              {lections.map((lection) => (
                <CardAdmin
                  key={lection._id}
                  data={lection}
                  deleteLectionHandler={deleteLectionHandler}
                  events={cardEvents}
                  badges={true}
                />
              ))}
            </div>
          )}
        </div>
      </section>
    </main>
  );
}

export default AdminLections;
