// SLIDE - type: slide
// CENTERED - type: fixed-center
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import { handleOverlay } from "../../redux/actions/uiActions";

// CONSTANTS
import { UI_NOTIFICATION_HIDE } from "../../сonstants/uiConstants";

function SlideNotification({ position }) {
  const dispatch = useDispatch();

  const notificationsList = useSelector((state) => state.ui.notifications);
  const { notifications } = notificationsList;

  useEffect(() => {
    if (notifications.length > 0) {
      if (notifications[0].type === "slide") {
        const interval = setInterval(() => {
          if (notifications.length > 0) {
            deleteNotification();
          }
        }, 3500);
        return () => {
          clearInterval(interval);
        };
      } else {
        dispatch(handleOverlay(true));
      }
    }
    //eslint-disable-next-line
  }, [notifications]);

  const deleteNotification = () => {
    dispatch({ type: UI_NOTIFICATION_HIDE });
    if (notifications[0].type === "fixed-center") {
      dispatch(handleOverlay(false));
    }
  };

  return (
    <>
      {notifications.some((notification) => notification.type === "slide") ? (
        <div className={`notification-container ${position}`}>
          {notifications.map((notification, i) => (
            <div key={i} className={`notification ${position}`}>
              <div
                className="notification-line"
                style={{ backgroundColor: notification.color }}
              ></div>
              <div className="notification-icon-main">
                <i
                  className={notification.icon}
                  style={{ color: notification.color }}
                ></i>
              </div>
              <div className="notification-content">
                <h6 className="notification-title">{notification.title}</h6>
              </div>
            </div>
          ))}
        </div>
      ) : (
        notifications.map((notification, i) => (
          <div key={i} className="notification-big-centered">
            <div className="close-icon" onClick={deleteNotification}>
              <i className="fal fa-times"></i>
            </div>
            <div className="notification-content">
              <div className="notification-icon">
                <i className={notification.icon}></i>
              </div>
              <h6>{notification.title}</h6>
              <p>{notification.description}</p>
              <button
                className="btn btn-x1 btn-black-fill"
                onClick={deleteNotification}
              >
                ОК
              </button>
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default SlideNotification;
