import React from "react";

import { Link } from "react-router-dom";

import SocialLinks from "../Buttons/Social";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="footer__top">
          <div
            className="footer__top--left"
            itemType="https://schema.org/Organization"
          >
            <div className="footer__text--address" itemProp="address">
              Верхняя Пышма
              <a
                href="https://yandex.ru/maps/20720/verhnyaya-pyshma/?from=api-maps&ll=60.568629%2C56.974479&mode=usermaps&origin=jsapi_2_1_74&um=constructor%3A897cdbbe5c833f3c2cc7ccfdeec646c946831b49f2b5d281401783e7bff4b854&z=17"
                rel="noopener noreferrer"
                target="_blank"
                className="footer__address--link"
                aria-label="Адрес магазина"
              >
                Успенский Проспект, 99
              </a>
            </div>
          </div>
          <div className="footer__top--right">
            <SocialLinks parentClass="footer" />
          </div>
        </div>
        <div className="footer__middle">
          <div className="footer__phones">
            <div className="footer__phone">
              <a
                href="tel:+73432269059"
                rel="noopener noreferrer"
                aria-label="Телефон книжного"
              >
                +7 343 226 90 59
              </a>
              ‒ Книжный магазин
            </div>
          </div>
          <div className="footer__pages">
            <Link to="/jobs" className="footer__link">
              Вакансии
            </Link>
            <Link to="/lectorium" className="footer__link">
              Лекторий
            </Link>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__copyright">
            ООО «КНИГИ», ИНН 6686074621, ОГРН 1156658107913
          </div>
          <div className="footer__documents">
            <a
              href="https://drugie-knigi.ru/uploads/contract_offer.pdf"
              rel="noopener noreferrer"
              target="_blank"
              aria-label="Публичная оферта"
            >
              Публичная оферта
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
