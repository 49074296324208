import React, { useState } from "react";

function Dropdown({ data, objProp, handleOnChange, placeholder, value }) {
  // COMPONENT STATE
  const [isOpen, setIsOpen] = useState(false);

  const handleDropDown = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleSelect = (item, action) => {
    action(item);
    setIsOpen(false);
  };

  return (
    <div
      className={`${
        isOpen ? "input__dropdown input__dropdown--open" : "input__dropdown"
      }`}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleOnChange(e.target.value)}
        onBlur={() => {
          setIsOpen(false);
        }}
      />
      <button
        type="button"
        className="input__dropdown--button"
        onClick={handleDropDown}
      >
        <i className="fal fa-chevron-down"></i>
      </button>
      <div className="input__dropdown--selection">
        {data.map((item) => (
          <span
            key={item[objProp]}
            onClick={() => handleSelect(item[objProp], handleOnChange)}
          >
            {item[objProp]}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;
