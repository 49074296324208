import api from "helpers/api";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_RESTORE_PASSWORD_REQUEST,
  USER_RESTORE_PASSWORD_SUCCESS,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAIL,
  USERS_ALL_REQUEST,
  USERS_ALL_SUCCESS,
  USERS_ALL_FAIL,
} from "сonstants/userConstants";

import { UI_NOTIFICATION_SHOW } from "сonstants/uiConstants";

// LOGIN ACTION
export const login = (email, password, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api.post(`/auth/login`, { email, password }, config);

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
    history.push("/");
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// LOGOUT ACTION
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({
    type: USER_LOGOUT,
  });
};

// REGISTER ACTION
export const register =
  (name, email, phone, password, history) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.post(
        `/auth/register`,
        { name, email, phone, password },
        config
      );

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      localStorage.setItem("userInfo", JSON.stringify(data));

      history.push("/");

      const notifications = [
        {
          type: "fixed-center",
          title: "Спасибо, ваша регистрация на сайте почти завершена!",
          description:
            "Пожалуйста, проверьте указанную электронную почту, туда мы выслали ссылку для подтверждения.",
          icon: "fal fal fa-check-circle",
        },
      ];

      dispatch({
        type: UI_NOTIFICATION_SHOW,
        payload: notifications,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// FORGOT PASSWORD ACTION
export const forgotPassword = (email, history) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await api.post(`/auth/forgotpassword`, { email }, config);

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });

    const notifications = [
      {
        type: "fixed-center",
        color: "#10e092",
        title: "На Ваш Email отправлено письмо",
        description:
          "Мы отправили Вам сообщение с инструкцией по восстановлению пароля",
        icon: "fal fal fa-envelope",
      },
    ];

    dispatch({
      type: UI_NOTIFICATION_SHOW,
      payload: notifications,
    });

    history.push("/");
  } catch (error) {
    // Slide notification with error
    const notifications = [
      {
        type: "slide",
        color: "#ff6c65",
        title:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
        icon: "fal fa-exclamation-circle",
      },
    ];
    dispatch({
      type: UI_NOTIFICATION_SHOW,
      payload: notifications,
    });
  }
};

// CREATE NEW PASSWORD ACTION
export const createNewPassword =
  (resetToken, password, history) => async (dispatch) => {
    try {
      dispatch({
        type: USER_RESTORE_PASSWORD_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await api.put(
        `/auth/restorepassword/${resetToken}`,
        { password },
        config
      );

      dispatch({
        type: USER_RESTORE_PASSWORD_SUCCESS,
        payload: data,
      });

      const notifications = [
        {
          type: "slide",
          color: "#10e092",
          title: "Вы успешно изменили пароль",
          icon: "fal fa-check-circle",
        },
      ];

      dispatch({
        type: UI_NOTIFICATION_SHOW,
        payload: notifications,
      });

      history.push("/");
    } catch (error) {
      // Slide notification with error
      const notifications = [
        {
          type: "slide",
          color: "#ff6c65",
          title:
            error.response && error.response.data.error
              ? error.response.data.error
              : error.error,
          icon: "fal fa-exclamation-circle",
        },
      ];
      dispatch({
        type: UI_NOTIFICATION_SHOW,
        payload: notifications,
      });
    }
  };

// USER INFO ACTION
export const getUser = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INFO_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const user = await api.get(`/auth/me`, config);

    dispatch({
      type: USER_INFO_SUCCESS,
      payload: user.data.data,
    });
  } catch (error) {
    dispatch({
      type: USER_INFO_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// ALL USERS (ADMIN)
export const listAllUsers =
  (page, limit, sort) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USERS_ALL_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const users = await api.get(
        `/users?${page ? `&page=${page}` : ""}limit=${limit}&${
          sort ? `&sort=${sort}` : ""
        }`,
        config
      );

      dispatch({
        type: USERS_ALL_SUCCESS,
        payload: users.data,
      });
    } catch (error) {
      dispatch({
        type: USERS_ALL_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
