// UI SIDEBAR
export const UI_SIDEBAR_OPEN = "UI_SIDEBAR_OPEN";
export const UI_SIDEBAR_CLOSE = "UI_SIDEBAR_CLOSE";
// UI OVERLAY
export const UI_OVERLAY_ACTIVE = "UI_OVERLAY_ACTIVE";
export const UI_OVERLAY_DISABLED = "UI_OVERLAY_DISABLED";
// UI SLIDE NOTIFICATIONS
export const UI_NOTIFICATION_SHOW = "UI_NOTIFICATION_SHOW";
export const UI_NOTIFICATION_HIDE = "UI_NOTIFICATION_HIDE";
// UI MODAL
export const UI_MODAL_SHOW = "UI_MODAL_SHOW";
export const UI_MODAL_HIDE = "UI_MODAL_HIDE";
