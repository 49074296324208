import React from "react";

function ServerError() {
  return (
    <main className="margined-navbar">
      <section className="section__stub--server-error">
        <div className="container">
          <div className="stub-wrapper">
            <i className="fal fa-exclamation-circle"></i>
            <h2>Ошибка сервера</h2>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ServerError;
