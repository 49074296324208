import React from "react";

function SectionStatAdmin({ data }) {
  return (
    <section className="section__admin-statistics">
      <div className="container">
        <div className="grid-container">
          {data.map((column) => (
            <div className="admin__statistics--container" key={column.id}>
              <h5>{column.title}</h5>
              <div className="admin__statistics--stat-wrapper">
                {column.stats.map((stat) => (
                  <div className="admin__statistics--stat" key={stat.id}>
                    <span>{stat.data}</span>
                    <p>{stat.title}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default SectionStatAdmin;
