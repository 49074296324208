import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// CONSTANTS
import { USER_REGISTER_RESET } from "сonstants/userConstants";
import { UI_NOTIFICATION_SHOW } from "сonstants/uiConstants";

// ACTIONS
import { register } from "redux/actions/userActions";

// COMPONENT
import Meta from "components/Meta/Meta";
import BackPageBtn from "components/Buttons/PrevPage";

// LOCAL COMPONENTS
import AuthInput from "./components/Input";

function RegisterPage({ history }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error: errors } = userRegister;

  useEffect(() => {
    if (errors) {
      const errorsForNotification = [];
      errors.split(",").map((error) =>
        errorsForNotification.push({
          type: "slide",
          color: "#ff6c65",
          title: error,
          icon: "fal fa-exclamation-circle",
        })
      );
      dispatch({ type: UI_NOTIFICATION_SHOW, payload: errorsForNotification });
      dispatch({ type: USER_REGISTER_RESET });
    }
  }, [dispatch, errors]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(register(name, email, phone, password, history));
  };

  return (
    <>
      <Meta title={"Регистрация — Книги, кофе и другие измерения"} />
      <main className="margined-navbar">
        <section className="section--auth-register">
          <div className="container">
            <div className="content-bar font-black">
              <BackPageBtn />
            </div>
            <div className="auth-container">
              <div className="column">
                <div className="column-header">
                  <div className="icon">
                    <i className="far fa-portal-enter"></i>
                  </div>
                  <div className="auth-links">
                    <Link to="/auth/login">Вход</Link>
                    <Link to="/auth/register" className="active-auth-link">
                      Регистрация
                    </Link>
                  </div>
                </div>
                <form onSubmit={handleFormSubmit} className="auth-form">
                  {loading && <h6>Загрузка</h6>}
                  <AuthInput
                    icon="far fa-user"
                    type="text"
                    placeholder="Имя, Фамилия"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <AuthInput
                    icon="far fa-envelope"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                  <AuthInput
                    icon="far fa-phone"
                    type="text"
                    placeholder="Телефон"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <AuthInput
                    icon="far fa-lock-alt"
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button className="btn btn-x1 btn-green-fill" type="submit">
                    Зарегистрироваться
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default RegisterPage;
