import React from "react";

function SocialLinks({ parentClass }) {
  return (
    <div className={`${parentClass}__socials social--links`}>
      <a
        href="https://www.instagram.com/drugie_izmereniya/"
        rel="noopener noreferrer"
        target="_blank"
        className="social__link"
        aria-label="Инстаграм"
      >
        Instagram
      </a>
      <a
        href="https://vk.com/drugie_izmereniya"
        rel="noopener noreferrer"
        target="_blank"
        className="social__link"
        aria-label="Вконтакте"
      >
        VK
      </a>
      <a
        href="https://facebook.com/drugie.izmereniya"
        rel="noopener noreferrer"
        target="_blank"
        className="social__link"
        aria-label="Фейсбук"
      >
        Facebook
      </a>
      <a
        href="https://t.me/drugie_izmereniya"
        rel="noopener noreferrer"
        target="_blank"
        className="social__link"
        aria-label="Телеграм"
      >
        Telegram
      </a>
    </div>
  );
}

export default SocialLinks;
