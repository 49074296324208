import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/store";
import Metrika from "./components/Yandex/Metrika";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Fonts
import "./styles/css/fonts.css";
// Icons
import "./styles/css/icons.css";
// Main Styles
import "./styles/index.scss";

ReactDOM.render(
  <Provider store={store}>
    <Metrika />
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
