import {
  UI_SIDEBAR_OPEN,
  UI_SIDEBAR_CLOSE,
  UI_OVERLAY_ACTIVE,
  UI_OVERLAY_DISABLED,
  UI_MODAL_SHOW,
  UI_MODAL_HIDE,
} from "сonstants/uiConstants";

export const handleSidebar = (status) => async (dispatch) => {
  if (status === true) {
    dispatch({ type: UI_SIDEBAR_OPEN, payload: status });
  } else {
    dispatch({ type: UI_SIDEBAR_CLOSE, payload: status });
  }
};

export const handleOverlay = (status) => async (dispatch) => {
  if (status === true) {
    dispatch({ type: UI_OVERLAY_ACTIVE, payload: status });
  } else {
    dispatch({ type: UI_OVERLAY_DISABLED, payload: status });
  }
};

export const handleModal = (status) => async (dispatch) => {
  if (status === true) {
    dispatch({ type: UI_MODAL_SHOW, payload: status });
  } else {
    dispatch({ type: UI_MODAL_HIDE, payload: status });
  }
};
