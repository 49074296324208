import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

// COMPONENTS
import EditorMenu from "./EditorMenu";

function TipTapEditor({ data, setEditorDescription }) {
  const editor = useEditor({
    extensions: [StarterKit, Link],
    content: data,
  });

  useEffect(() => {
    if (editor) {
      setEditorDescription(editor.getHTML());
    }
  });

  return (
    <div className="editor__tip-tap">
      <EditorMenu editor={editor} />
      <EditorContent editor={editor} className="editor__textarea" />
    </div>
  );
}

export default TipTapEditor;
