import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

// ACTIONS
import { listWorkerDetails } from "redux/actions/workerActions";

// COMPONENTS
import Spinner from "components/Loaders/Spinner";
import BackPageBtn from "components/Buttons/PrevPage";

function ConsultantPage({ match }) {
  const workerId = match.params.id;
  const dispatch = useDispatch();

  const workerDetails = useSelector((state) => state.workers.workerDetails);
  const { loading, worker } = workerDetails;

  useEffect(() => {
    dispatch(listWorkerDetails(workerId));
  }, [dispatch, workerId]);

  return (
    <main className="margined-navbar">
      <section className="section--worker-single">
        <div className="container">
          <div className="content-bar font-black">
            <BackPageBtn />
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div className="profile-wrapper">
              <div className="profile-image">
                <img src={worker.image} alt="" />
              </div>
              <div className="profile-information">
                <p className="profile-department font-red">{worker.topic}</p>
                <h5 className="profile-name">{worker.name}</h5>
                <div className="profile-description">
                  {parse(`${worker.description}`)}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
}

export default ConsultantPage;
