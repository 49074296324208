import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobCook() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Повар-универсал в кофейню</h4>
          <div className="content">
            <p className="font-bold">Добрый день!</p>
            <p className="font-bold">
              Мы запускаем производственный цех, где будем готовить завтраки,
              горячие перекусы и простые десерты. Мы ищем аккуратных и активных
              людей, которые:
            </p>
            <li>— любят готовить</li>
            <li>— умеют работать в команде</li>
            <li>— имеют опыт работы поваром от 2-х лет</li>
            <li>— любознательны, имеют много интересов или хобби</li>
            <p className="font-bold">Обязанности:</p>
            <li>— готовить завтраки, горячие перекусы и простые десерты;</li>
            <li>— делать заготовки, в том числе и для напитков;</li>
            <li>— поддерживать чистоту на своем рабочем месте</li>
            <li>
              — контролировать сроки реализации и товарное соседство, товарные
              остатки
            </li>
            <li>— готовить смену к открытию и закрытию</li>
            <p className="font-bold">Что мы предлагаем:</p>
            <li>
              окладно-премиальная система оплаты труда, от 130 рублей в час;
            </li>
            <li>— гибкий график работы;</li>
            <li>— официальное трудоустройство, зарплата два раза в месяц;</li>
            <li>— доставка на рабочую смену и развоз служебным транспортом;</li>
            <li>— первичное обучение у приглашенного шеф повара.</li>
            <li>— скидки на книги, мероприятия лектория и кофе</li>
            <p>
              Ждем ваше резюме на почту почту{" "}
              <a href="mailto: huntingcoffee@yandex.ru">
                huntingcoffee@yandex.ru
              </a>
            </p>
            <p>
              Подробности по телефону:
              <a href="tel:+79222247102"> +79222247102</a>, Марина, управляющая
              кофейни
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobCook;
