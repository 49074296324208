import React from "react";

import { getRusMonthDative } from "middleware/dates";

function CardUser({ data }) {
  return (
    <div className="card__admin--user">
      <div className="card__admin--user-container">
        <small className="user__container--title">имя / фамилия</small>
        <span className="user--name">{data.name}</span>
      </div>
      <div className="card__admin--user-container">
        <small className="user__container--title">email</small>
        {data.email}
      </div>
      <div className="card__admin--user-container">
        <small className="user__container--title">телефон</small>
        {data.phone}
      </div>
      <div className="card__admin--user-container">
        <small className="user__container--title">Дата регистрации</small>
        {`${data.createdAt.slice(8, 10)} ${getRusMonthDative(
          data.createdAt.slice(6, 7)
        ).toLowerCase()} ${data.createdAt.slice(0, 4)}`}
      </div>
      <div className="card__admin--user-container">
        <small className="user__container--title">мероприятий</small>
        {data.lections.length}
      </div>
      <div className="card__admin--user-container">
        <small className="user__container--title">подтвержден</small>
        <span className="user--confirmed">
          {data.isEmailConfirmed ? (
            <i className="fal fa-check-circle"></i>
          ) : (
            <i className="fal fa-exclamation-circle"></i>
          )}
        </span>
      </div>
    </div>
  );
}

export default CardUser;
