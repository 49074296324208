import React from "react";
import { YMInitializer } from "react-yandex-metrika";

function Metrika() {
  return (
    <>
      {process.env.NODE_ENV !== "development" ? (
        <YMInitializer
          accounts={[69826882]}
          options={{
            webvisor: true,
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
          }}
          version="2"
        />
      ) : null}
    </>
  );
}

export default Metrika;
