import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function Job1CProgrammer() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>1C программист</h4>
          <div className="content">
            <p>
              Наша вакансия не подразумевает удаленной работы, мы не ищем
              фрилансеров и аутсорсеров. У нас сложный интересный проект,
              который требует постоянного внимания и умения решать проблемы на
              месте. Мы — главная культурная площадка северного Екатеринбурга с
              кучей ежедневных мероприятий, у нас лучшая подборка книг, самые
              умные продавцы и вкуснейший кофе в радиусе нескольких сотен
              километров. А еще у нас есть несколько филиалов и планируются
              новые проекты. Мы знаем, что будущее за образованием и наукой, а
              самый ценный ресурс в ближайшие десятилетия — творческое и
              критическое мышление. Поэтому мы внедряем самые современные
              технологии в ритейле: от простой бизнес-аналитики до сложных RFID,
              интернета вещей и BigData. Все для того, чтобы у людей оставалось
              как можно больше времени и свободы для саморазвития.
            </p>
            <p className="font-bold">Кто нам нужен:</p>
            <p>
              Наша основная учетная система — 1С, поэтому нам нужен человек,
              который чувствует себя в ней как рыба в воде: умеет формировать
              нужные управленческие и бухгалтерские отчеты, дописывать
              обработки, но так чтобы они не слетали при обновлениях,
              продумывать логичные архитектуры интеграций с другими системами,
              настраивать нестандартные механизмы обмена данных, при этом не
              будет бояться настроек смежного оборудования: касс, ТСД,
              эквайринга, и тп. Задач и идей у нас много, поэтому мы ищем
              человека с опытом, разбирающегося в типовых конфигурациях и
              умеющего прогнозировать результаты.
            </p>
            <p className="font-bold">Обязанности:</p>
            <p>
              Поддерживать рабочее состояние товароучетной системы небольшой
              торговой сети и развивать ее функционал. Разрабатывать новые
              программные модули, обновлять и оптимизировать имеющиеся обработки
              и интеграции. Работать с обращениями пользователей: бухгалтеров и
              товароведов. Оперативно реагировать на сбои товароучетной системы,
              влияющих на работу магазинов.
            </p>
            <p className="font-bold">Условия:</p>
            <li>
              — Мы находимся в Верхней Пышме, поэтому каждый день по утрам и
              вечерам предусмотрен трансфер от метро Уралмаш и обратно;
            </li>
            <li>
              — Средний возраст наших сотрудников — 26 лет. Практически у всех
              высшее, чаще всего, гуманитарное образование. Все влюблены в свою
              работу без всякой корпоративной пропаганды и прочей ерунды,
              поэтому дружны и инициативны. Токсичных товарищей мы отсеиваем
              независимо от их личной продуктивности;
            </li>
            <li>
              — Кофейня работает с 7 утра, магазин с 10, а закрываются все
              дружно в 9 вечера — каждый день без выходных. Поэтому каждый
              сотрудник офиса выбирает себе удобный график, вписывающийся в эти
              границы: кому-то удобнее с понедельника по пятницу с 8 до 17,
              кому-то со среды по воскресенье с полудня до закрытия, но большая
              часть коллектива приезжает к 10 и уезжает в 19 часов с выходными
              по субботам и воскресеньям;
            </li>
            <li>
              — Благодаря собственной кофейне и площадке для мероприятий,
              рабочий день может быть весьма разнообразным и насыщенным с
              периодической сменой локаций;
            </li>
            <li>
              — Официальное трудоустройство, полностью белые зарплата и премия.
              Рабочее место находится в нашем головном проекте в Верхней Пышме в
              общем кабинете айти-персонала. Всем сотрудникам полагается
              бесплатный кофе и скидки в книжный магазин;
            </li>
            <p>
              Сейчас вам достаточно написать на электронную почту
              <a href="mailto: ssv@drugie-knigi.ru"> ssv@drugie-knigi.ru</a> с
              темой письма «Кандидат в программисты 1С. В ответ мы отправим вам
              небольшую анкету. Если от нас не будет ответа больше трех дней,
              проверьте папку «Спам», а потом продублируйте ваше письмо.
            </p>
            <p>
              Если и это не помогло, напишите нам в соцсетях или позвоните по
              телефону <a href="tel:+79122000199">+79122000199</a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Job1CProgrammer;
