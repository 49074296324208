import React from "react";
import { Link } from "react-router-dom";

function EmailConfirmed() {
  return (
    <main className="margined-navbar">
      <section className="section--stub-email-already-confirmed">
        <div className="container">
          <div className="stub-content">
            <h1>Email уже подтвержден</h1>
            <Link to="/" className="btn btn-x1 btn-black">
              На главную
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default EmailConfirmed;
