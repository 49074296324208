import React from "react";

function Label({ styles, text }) {
  return (
    <div className={`label ${styles}`}>
      <span>{text}</span>
    </div>
  );
}

export default Label;
