import api from "helpers/api";

import {
  LECTION_LIST_REQUEST,
  LECTION_LIST_SUCCESS,
  LECTION_LIST_FAIL,
  LECTION_FILTERS_REQUEST,
  LECTION_FILTERS_SUCCESS,
  LECTION_FILTERS_FAIL,
  LECTION_DETAILS_REQUEST,
  LECTION_DETAILS_SUCCESS,
  LECTION_DETAILS_FAIL,
  LECTION_DELETE_REQUEST,
  LECTION_DELETE_SUCCESS,
  LECTION_DELETE_FAIL,
  LECTION_CREATE_REQUEST,
  LECTION_CREATE_SUCCESS,
  LECTION_CREATE_FAIL,
  LECTION_UPDATE_REQUEST,
  LECTION_UPDATE_SUCCESS,
  LECTION_UPDATE_FAIL,
  LECTION_VISITORS_REQUEST,
  LECTION_VISITORS_SUCCESS,
  LECTION_VISITORS_FAIL,
  LECTION_CHECK_VISITOR_REQUEST,
  LECTION_CHECK_VISITOR_SUCCESS,
  LECTION_CHECK_VISITOR_FAIL,
  LECTION_DELETE_VISITOR_REQUEST,
  LECTION_DELETE_VISITOR_SUCCESS,
  LECTION_DELETE_VISITOR_FAIL,
  LECTION_ARCHIEVE_REQUEST,
  LECTION_ARCHIEVE_SUCCESS,
  LECTION_ARCHIEVE_FAIL,
  LECTION_SINGLE_ARCHIVE_REQUEST,
  LECTION_SINGLE_ARCHIVE_SUCCESS,
  LECTION_SINGLE_ARCHIVE_FAIL,
  LECTION_ARCHIVE_TRANSFER_REQUEST,
  LECTION_ARCHIVE_TRANSFER_SUCCESS,
  LECTION_ARCHIVE_TRANSFER_FAIL,
} from "сonstants/lectionConstants";

// ALL LECTIONS
export const listLections = (filter, limit, isFinished) => async (dispatch) => {
  try {
    dispatch({ type: LECTION_LIST_REQUEST });

    // API REQUESTS
    const lections = await api.get(
      `/lections?${filter ? `filter=${filter}` : ""}&isFinished=${isFinished}${
        limit ? `&limit=${limit}` : ""
      }&sort=+dateWithTime`
    );

    dispatch({
      type: LECTION_LIST_SUCCESS,
      payload: lections.data,
    });
  } catch (error) {
    dispatch({
      type: LECTION_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// FILTERS LECTION
export const listFilters = () => async (dispatch) => {
  try {
    dispatch({ type: LECTION_FILTERS_REQUEST });
    // API REQUEST
    const filters = await api.get("/lections/filters/all");

    dispatch({
      type: LECTION_FILTERS_SUCCESS,
      payload: filters,
    });
  } catch (error) {
    dispatch({
      type: LECTION_FILTERS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// SINGLE LECTION
export const listLectionDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: LECTION_DETAILS_REQUEST });
    const { data } = await api.get(`/lections/${id}`);

    dispatch({
      type: LECTION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LECTION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

// DELETE LECTION (ADMIN)
export const deleteLection = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LECTION_DELETE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    await api.delete(`/lections/delete/${id}`, config);

    dispatch({
      type: LECTION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LECTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// CREATE LECTION (ADMIN)
export const createLection = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LECTION_CREATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    const { data } = await api.post("/lections/add", {}, config);

    dispatch({
      type: LECTION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LECTION_CREATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// UPDATE LECTION (ADMIN)
export const updateLection = (lection) => async (dispatch, getState) => {
  try {
    dispatch({ type: LECTION_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    const { data } = await api.put(
      `/lections/edit/${lection._id}`,
      lection,
      config
    );

    dispatch({
      type: LECTION_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LECTION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// VISITORS (ADMIN)
export const listLectionVisitors = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LECTION_VISITORS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await api.get(`/lections/${id}/visitors`, config);

    dispatch({
      type: LECTION_VISITORS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LECTION_VISITORS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// CHECK VISITOR (ADMIN)
export const checkVisitor =
  (lectionID, subscriptionID, visitorID) => async (dispatch, getState) => {
    try {
      dispatch({ type: LECTION_CHECK_VISITOR_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUEST
      await api.put(
        `/lections/${lectionID}/visitors/${subscriptionID}/${visitorID}`,
        {},
        config
      );

      dispatch({
        type: LECTION_CHECK_VISITOR_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LECTION_CHECK_VISITOR_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// DELETE VISITOR (ADMIN)
export const deleteVisitor =
  (lectionId, visitorId) => async (dispatch, getState) => {
    try {
      dispatch({ type: LECTION_DELETE_VISITOR_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUEST
      await api.delete(`/lections/${lectionId}/visitors/${visitorId}`, config);

      dispatch({
        type: LECTION_DELETE_VISITOR_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: LECTION_DELETE_VISITOR_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// ALL ARCHIVE LECTIONS
export const listArchiveLections =
  (startPeriod, endPeriod, limit, sort, pageNumber, searchFields, searchBody) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LECTION_ARCHIEVE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUESTS
      const lections = await api.get(
        `/lections/archive/all?${
          startPeriod ? `&startPeriod=${startPeriod}` : ""
        }${endPeriod ? `&endPeriod=${endPeriod}` : ""}${
          limit ? `&limit=${limit}` : ""
        }${sort ? `&sort=${sort}` : ""}${
          pageNumber ? `&page=${pageNumber} : ` : ""
        }${
          searchFields
            ? `&search[fields]=${searchFields}&search[body]=${searchBody}`
            : ""
        }`,
        config
      );

      dispatch({
        type: LECTION_ARCHIEVE_SUCCESS,
        payload: lections.data,
      });
    } catch (error) {
      dispatch({
        type: LECTION_ARCHIEVE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// SINGLE ARCHIVE LECTION
export const listArchiveLectionDetails = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    dispatch({ type: LECTION_SINGLE_ARCHIVE_REQUEST });
    const { data } = await api.get(`/lections/archive/lection/${id}`, config);

    dispatch({
      type: LECTION_SINGLE_ARCHIVE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: LECTION_SINGLE_ARCHIVE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const transferArchiveLection = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LECTION_ARCHIVE_TRANSFER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUEST
    await api.post(`/lections/archive/lection/${id}/transfer`, {}, config);

    dispatch({
      type: LECTION_ARCHIVE_TRANSFER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LECTION_ARCHIVE_TRANSFER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const copyArchiveLection = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LECTION_ARCHIVE_TRANSFER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUEST
    await api.post(`/lections/archive/lection/${id}/copy`, {}, config);

    dispatch({
      type: LECTION_ARCHIVE_TRANSFER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LECTION_ARCHIVE_TRANSFER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};
