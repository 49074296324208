import React, { useState } from "react";

function AuthInput({ icon, type, placeholder, value, onChange }) {
  const [onFocus, setOnFocus] = useState(false);
  const handleOnFocusInput = () => {
    setOnFocus(true);
  };

  const handleOnBlurInput = () => {
    setOnFocus(false);
  };

  return (
    <div
      className={onFocus ? "input-group active-input-group" : "input-group"}
      onFocus={handleOnFocusInput}
      onBlur={handleOnBlurInput}
    >
      <div className="icon-placeholder">
        <i className={icon}></i>
      </div>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default AuthInput;
