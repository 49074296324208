import React from "react";

// MIDDLEWARES
import { getRusMonthDative } from "../../middleware/dates";

function CardPayment({ payment }) {
  return (
    <div className="card__payment">
      <div
        className={`payment__wrapper  ${
          payment.status === "succeeded"
            ? "payment--success"
            : payment.status === "refunded"
            ? "payment--refunded"
            : "payment--error"
        }`}
      >
        <div className="payment__column column--title">
          <div className="payment__status-line"></div>
          <span className="payment__title">{`${payment.description}`}</span>
          <span className="payment__date">
            {payment.createdAt.slice(8, 10)}{" "}
            {getRusMonthDative(
              Number(payment.createdAt.slice(5, 7))
            ).toLowerCase()}{" "}
            {payment.createdAt.slice(0, 4)}
          </span>
        </div>
        <div className="payment__column column--status">
          {payment.status === "succeeded" ? (
            <div className="payment__status-icon icon--success">
              <i className="fal fa-check-circle"></i>
            </div>
          ) : payment.status === "refunded" ? (
            <div className="payment__status-icon icon--refunded">
              <i className="far fa-undo"></i>
            </div>
          ) : (
            <div className="payment__status-icon icon--error">
              <i className="fal fa-exclamation-circle"></i>
            </div>
          )}
          <span className="payment__status-text"></span>
        </div>
        <div className="payment__price">
          <span>{payment.amount} ₽</span>
        </div>
        {payment.status === "refunded" || payment.status === "canceled" ? (
          <div className="payment__info">
            {payment.status === "refunded" ? (
              <div>
                <span className="payment__refunded-amount">
                  Возвращено: {payment.refundedAmount} ₽
                </span>
                <span className="payment__refunded-date">
                  Дата: {payment.refundedAt.slice(8, 10)}{" "}
                  {getRusMonthDative(
                    Number(payment.refundedAt.slice(5, 7))
                  ).toLowerCase()}{" "}
                  {payment.refundedAt.slice(0, 4)}
                </span>
              </div>
            ) : (
              <div>
                <span className="payment__cancel-details">
                  Причина: {payment.cancellationDetails}
                </span>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CardPayment;
