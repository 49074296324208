import React from "react";
import { Link } from "react-router-dom";

function Button({ type, styles, text, icon, action, link }) {
  return type === "button" ? (
    <button className={styles} onClick={() => action()}>
      <div className="hover--circle"></div>
      <span>{text}</span>
      {icon ? <i className={icon}></i> : null}
    </button>
  ) : type === "link" ? (
    <Link to={link} className={styles}>
      <div className="hover--circle"></div>
      <span>{text}</span>
      {icon ? <i className={icon}></i> : null}
    </Link>
  ) : (
    <span>Type is not specified</span>
  );
}

export default Button;
