import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobBrandManager() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Бренд-менеджер сувенирной продукции</h4>
          <div className="content">
            <p>
              С этого полугодия мы начинаем активно развивать направление работы
              по производству и продаже тематической сувенирной продукции, как в
              самом магазине, так и в наших филиалах в Верхней Пышме и
              Екатеринбурге.
            </p>
            <p>
              Темы сувениров, которые мы хотим производить смежны с нашими
              интересами: наука и техника, искусство и литература, история и
              другие социальные науки, книги и чтение во всех проявлениях,
              родной Урал и тп.
            </p>
            <p>
              Мы даже начали пробовать идти в эту сторону, получили небольшой
              опыт, но пока сконцентрировали силы на развитии книжного магазина
              и лектория. Поэтому мы ищем человека, готового возглавить
              интересное нам направление сувениров.
            </p>
            <li>
              — Мы находимся в Верхней Пышме, поэтому каждый день по утрам и
              вечерам предусмотрен трансфер от метро Уралмаш и обратно;
            </li>
            <li>
              — Средний возраст наших сотрудников — 26 лет. Практически у всех
              высшее, чаще всего, гуманитарное образование. Все влюблены в свою
              работу без всякой корпоративной пропаганды и прочей ерунды,
              поэтому дружны и инициативны. Токсичных товарищей мы отсеиваем
              независимо от их личной продуктивности;
            </li>
            <li>
              — Кофейня работает с 7 утра, магазин с 10, а закрываются все
              дружно в 9 вечера — каждый день без выходных. Поэтому каждый
              сотрудник офиса выбирает себе удобный график, вписывающийся в эти
              границы: кому-то удобнее с понедельника по пятницу с 8 до 17,
              кому-то со среды по воскресенье с полудня до закрытия, но большая
              часть коллектива приезжает к 10 и уезжает в 19 часов с выходными
              по субботам и воскресеньям;
            </li>
            <li>
              — Благодаря собственной кофейне и площадке для мероприятий,
              рабочий день может быть весьма разнообразным и насыщенным с
              периодической сменой локаций;
            </li>
            <p className="font-bold">Мы ищем человека, который будет:</p>
            <li>
              — Придумывать различные тематические сувениры на разнообразных
              носителях;
            </li>
            <li>
              — Составлять маркетинговый план и финансовое обоснование продукта.
              Прописывать целевую аудиторию товаров и прогнозировать их
              экономическую эффективность;
            </li>
            <li>
              — Обеспечивать полный цикл производства: искать дизайнеров и
              иллюстраторов для реализации идей, производителей, налаживать
              логистику;
            </li>
            <li>
              — Искать существующие на рынке тематические сувениры и выводить их
              в продажу наших магазинов;
            </li>
            <p className="font-bold">Требования:</p>
            <p>
              Работы будет много. Справиться с ней удаленно, по совместительству
              или без должного опыта физически невозможно. Вы должны уметь
              планировать свои действия и бюджеты, грамотно структурировать
              концепции и понятным языком доносить идеи исполнителям. При этом
              быть довольно разносторонним и изобретательным человеком: сложно
              придумывать интересные товары, если вы не разбираетесь в теме.
              Поэтому приветствуется широкий кругозор, знания в области
              искусствоведения, истории, естественных наук и литературы хотя бы
              на уровне имен и основных кейсов.
            </p>
            <p className="font-bold">Условия:</p>
            <p>
              Оклад 35000 руб., и премиальная часть в виде % от продаж
              сувениров. Оформление на работу исключительно официальное,
              зарплата полностью белая, выплачивается два раза в месяц без
              задержек и переносов. Разумеется, для всех сотрудников
              предусмотрены скидки на книги, мероприятия и меню в кофейне.
            </p>
            <p>
              Сейчас вам достаточно написать на электронную почту
              <a href="mailto: ssv@drugie-knigi.ru"> ssv@drugie-knigi.ru</a> с
              темой письма «Кандидат бренд-менеджер». В ответ мы отправим вам
              небольшую анкету. Если от нас не будет ответа больше трех дней,
              проверьте папку «Спам», а потом продублируйте ваше письмо.
            </p>
            <p>
              Если и это не помогло, напишите нам в соцсетях или позвоните по
              телефону <a href="tel:+79122000199">+79122000199</a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobBrandManager;
