import {
  SUBSCRIBE_LECTION_REQUEST,
  SUBSCRIBE_LECTION_SUCCESS,
  SUBSCRIBE_LECTION_FAIL,
  UNSUBSCRIBE_LECTION_REQUEST,
  UNSUBSCRIBE_LECTION_SUCCESS,
  UNSUBSCRIBE_LECTION_FAIL,
  SUBSCRIPTION_GET_REQUEST,
  SUBSCRIPTION_GET_SUCCESS,
  SUBSCRIPTION_GET_FAIL,
  SUBSCRIPTION_RESET,
} from "сonstants/subscribeConstants";

// SUBSCRIBE TO LECTION (PROTECTED)
export const subscribeLectionReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_LECTION_REQUEST:
      return { loading: true };
    case SUBSCRIBE_LECTION_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case SUBSCRIBE_LECTION_FAIL:
      return { loading: false, error: action.payload };
    case SUBSCRIPTION_RESET: {
      return {};
    }
    default:
      return state;
  }
};

// UNSUBSCRIBE LECTION (PROTECTED)
export const unsubscribeLectionReducer = (state = {}, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_LECTION_REQUEST:
      return { loading: true };
    case UNSUBSCRIBE_LECTION_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case UNSUBSCRIBE_LECTION_FAIL:
      return { loading: false, error: action.payload };
    case SUBSCRIPTION_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const subscriptionStatus = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIPTION_GET_REQUEST:
      return { loading: true };
    case SUBSCRIPTION_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        isSubscribed: action.payload,
      };
    case SUBSCRIPTION_GET_FAIL:
      return { loading: false, error: action.payload };
    case SUBSCRIPTION_RESET:
      return {};
    default:
      return state;
  }
};
