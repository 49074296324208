export const handleSmoothScroll = (hash, action) => {
  const headerOffset = 60;
  let target = document.querySelector(hash);
  let elementPosition = target.offsetTop;
  let offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });

  if (action) {
    action();
  }
};
