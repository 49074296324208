// CREATE VISITOR (ADMIN)
export const VISITOR_CREATE_REQUEST = "VISITOR_CREATE_REQUEST";
export const VISITOR_CREATE_SUCCESS = "VISITOR_CREATE_SUCCESS";
export const VISITOR_CREATE_FAIL = "VISITOR_CREATE_FAIL";
export const VISITOR_CREATE_RESET = "VISITOR_CREATE_RESET";

export const VISITORS_GET_REQUEST = "VISITORS_GET_REQUEST";
export const VISITORS_GET_SUCCESS = "VISITORS_GET_SUCCESS";
export const VISITORS_GET_FAIL = "VISITORS_GET_FAIL";
export const VISITORS_GET_RESET = "VISITORS_GET_RESET";
