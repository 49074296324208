// ALL LECTIONS
export const LECTION_LIST_REQUEST = "LECTION_LIST_REQUEST";
export const LECTION_LIST_SUCCESS = "LECTION_LIST_SUCCESS";
export const LECTION_LIST_FAIL = "LECTION_LIST_FAIL";

// FILTERS LECTION
export const LECTION_FILTERS_REQUEST = "LECTION_FILTERS_REQUEST";
export const LECTION_FILTERS_SUCCESS = "LECTION_FILTERS_SUCCESS";
export const LECTION_FILTERS_FAIL = "LECTION_FILTERS_FAIL";

// SINGLE LECTION
export const LECTION_DETAILS_REQUEST = "LECTION_DETAILS_REQUEST";
export const LECTION_DETAILS_SUCCESS = "LECTION_DETAILS_SUCCESS";
export const LECTION_DETAILS_FAIL = "LECTION_DETAILS_FAIL";
export const LECTION_DETAILS_RESET = "LECTION_DETAILS_RESET";

// DELETE LECTION (ADMIN)
export const LECTION_DELETE_REQUEST = "LECTION_DELETE_REQUEST";
export const LECTION_DELETE_SUCCESS = "LECTION_DELETE_SUCCESS";
export const LECTION_DELETE_FAIL = "LECTION_DELETE_FAIL";

// CREATE LECTION (ADMIN)
export const LECTION_CREATE_REQUEST = "LECTION_CREATE_REQUEST";
export const LECTION_CREATE_SUCCESS = "LECTION_CREATE_SUCCESS";
export const LECTION_CREATE_FAIL = "LECTION_CREATE_FAIL";
export const LECTION_CREATE_RESET = "LECTION_CREATE_RESET";

// UPDATE LECTION (ADMIN)
export const LECTION_UPDATE_REQUEST = "LECTION_UPDATE_REQUEST";
export const LECTION_UPDATE_SUCCESS = "LECTION_UPDATE_SUCCESS";
export const LECTION_UPDATE_FAIL = "LECTION_UPDATE_FAIL";
export const LECTION_UPDATE_RESET = "LECTION_UPDATE_RESET";

// GET LECTION VISITORS (ADMIN)
export const LECTION_VISITORS_REQUEST = "LECTION_VISITORS_REQUEST";
export const LECTION_VISITORS_SUCCESS = "LECTION_VISITORS_SUCCESS";
export const LECTION_VISITORS_FAIL = "LECTION_VISITORS_FAIL";
export const LECTION_VISITORS_RESET = "LECTION_VISITORS_RESET";

// CHECK VISITOR (ADMIN)
export const LECTION_CHECK_VISITOR_REQUEST = "LECTION_CHECK_VISITOR_REQUEST";
export const LECTION_CHECK_VISITOR_SUCCESS = "LECTION_CHECK_VISITOR_SUCCESS";
export const LECTION_CHECK_VISITOR_FAIL = "LECTION_CHECK_VISITOR_FAIL";

export const LECTION_DELETE_VISITOR_REQUEST = "LECTION_DELETE_VISITOR_REQUEST";
export const LECTION_DELETE_VISITOR_SUCCESS = "LECTION_DELETE_VISITOR_SUCCESS";
export const LECTION_DELETE_VISITOR_FAIL = "LECTION_DELETE_VISITOR_FAIL";

// GET LECTIONS ARCHIEVE (ADMIN)
export const LECTION_ARCHIEVE_REQUEST = "LECTION_ARCHIEVE_REQUEST";
export const LECTION_ARCHIEVE_SUCCESS = "LECTION_ARCHIEVE_SUCCESS";
export const LECTION_ARCHIEVE_FAIL = "LECTION_ARCHIEVE_FAIL";

// GET SINGLE ARCHIEVED LECTION (ADMIN)
export const LECTION_SINGLE_ARCHIVE_REQUEST = "LECTION_SINGLE_ARCHIVE_REQUEST";
export const LECTION_SINGLE_ARCHIVE_SUCCESS = "LECTION_SINGLE_ARCHIVE_SUCCESS";
export const LECTION_SINGLE_ARCHIVE_FAIL = "LECTION_SINGLE_ARCHIVE_FAIL";
export const LECTION_SINGLE_ARCHIVE_RESET = "LECTION_SINGLE_ARCHIVE_RESET";

export const LECTION_ARCHIVE_TRANSFER_REQUEST =
  "LECTION_ARCHIVE_TRANSFER_REQUEST";
export const LECTION_ARCHIVE_TRANSFER_SUCCESS =
  "LECTION_ARCHIVE_TRANSFER_SUCCESS";
export const LECTION_ARCHIVE_TRANSFER_FAIL = "LECTION_ARCHIVE_TRANSFER_FAIL";
export const LECTION_ARCHIVE_TRANSFER_RESET = "LECTION_ARCHIVE_TRANSFER_RESET";
