import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import {
  listFilters,
  listLectionDetails,
  updateLection,
} from "redux/actions/lectionActions";

// CONSTANTS
import { API_PROXY } from "сonstants/apiConstants";
import {
  LECTION_UPDATE_RESET,
  LECTION_DETAILS_RESET,
} from "сonstants/lectionConstants";

// COMPONENTS
import Spinner from "components/Loaders/Spinner";
import InputWrapper from "components/Inputs/InputWrapper";
import InputStandart from "components/Inputs/InputStandart";
import DropDown from "components/Inputs/Dropdown";
import ToggleBtn from "components/Buttons/Toggle";
import Editor from "components/Editor/Editor";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function UpdateLection({ match, history }) {
  const lectionId = match.params.id;

  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [topicForFilters, setTopicForFilters] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [date, setDate] = useState("");
  const [price, setPrice] = useState("");
  const [startTime, setStartTime] = useState("");
  const [image, setImage] = useState("");
  const [editorDescription, setEditorDescription] = useState("");
  const [lecturer, setLecturer] = useState("");
  const [isRegisterOpen, setRegister] = useState(null);
  const [isFinished, setFinished] = useState(null);
  const [isPaid, setIsPaid] = useState(null);
  const [isPriorityFilter, setPriorityFilter] = useState(null);
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  const lectionDetails = useSelector((state) => state.lections.lectionDetails);
  const { loading, error, lection } = lectionDetails;

  const lectionUpdate = useSelector((state) => state.lections.lectionUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = lectionUpdate;

  // EXISTING FILTERS FOR CATEGORY SELECTOR
  const lectionFilters = useSelector((state) => state.lections.lectionFilters);
  const { filters } = lectionFilters;

  useEffect(() => {
    if (successUpdate) {
      history.push("/admin/lections");
    }
    if (!lection.title || lection._id !== lectionId) {
      dispatch(listLectionDetails(lectionId));
      dispatch(listFilters());
      return;
    }
    setTitle(lection.title);
    setTopic(lection.topic);
    setTopicForFilters(lection.topicForFilters);
    setTotalAmount(lection.totalAmount);
    setDate(lection.date);
    setPrice(lection.price);
    setStartTime(lection.startTime);
    setImage(lection.image);
    setLecturer(lection.lecturer);
    setRegister(lection.isRegisterOpen);
    setFinished(lection.isFinished);
    setIsPaid(lection.isPaid);
    setPriorityFilter(lection.isPriorityFilter);

    return () => {
      if (successUpdate) {
        dispatch({ type: LECTION_UPDATE_RESET });
      }
      dispatch({ type: LECTION_DETAILS_RESET });
    };
  }, [dispatch, history, lectionId, lection, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `${API_PROXY}/upload`,
        formData,
        config
      );
      // Remove absolute path
      const imagePathArray = data.split("/");
      const formattedImageSrc = `/${
        imagePathArray[imagePathArray.length - 2]
      }/${imagePathArray[imagePathArray.length - 1]}`;

      setImage(formattedImageSrc);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const handleToggleButton = (value, stateFunc) => {
    stateFunc(value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateLection({
        _id: lectionId,
        title,
        topic,
        topicForFilters,
        totalAmount,
        date,
        price,
        startTime,
        image,
        description: editorDescription,
        lecturer,
        isRegisterOpen,
        isFinished,
        isPaid,
        isPriorityFilter,
      })
    );
  };

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Редактирование события" />
      <section className="section__admin--lection-edit">
        <div className="container">
          {loadingUpdate && <Spinner />}
          {errorUpdate && <h6>{errorUpdate}</h6>}
          {loading ? (
            <Spinner />
          ) : error ? (
            <h6>{error}</h6>
          ) : (
            <form
              className="lection-management-form"
              onSubmit={(e) => submitHandler(e)}
            >
              <div className="grid-container">
                <InputWrapper label="Онлайн оплата">
                  <ToggleBtn
                    isChecked={isPaid}
                    onClick={(e) =>
                      handleToggleButton(e.target.checked, setIsPaid)
                    }
                  />
                </InputWrapper>
                <InputWrapper label="Включить регистрацию">
                  <ToggleBtn
                    isChecked={isRegisterOpen}
                    onClick={(e) =>
                      handleToggleButton(e.target.checked, setRegister)
                    }
                  />
                </InputWrapper>
                <InputWrapper label="Завершить мероприятие">
                  <ToggleBtn
                    isChecked={isFinished}
                    onClick={(e) =>
                      handleToggleButton(e.target.checked, setFinished)
                    }
                  />
                </InputWrapper>
                <InputWrapper label="Заголовок">
                  <InputStandart
                    type="text"
                    placeholder="Заголовок"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Категория">
                  <DropDown
                    data={filters.data ? filters.data : []}
                    objProp="topic"
                    handleOnChange={setTopic}
                    placeholder="Физика"
                    value={topic}
                  />
                </InputWrapper>
                <InputWrapper label="Категория на англ (через -)">
                  <DropDown
                    data={filters.data ? filters.data : []}
                    objProp={"topicForFilters"}
                    handleOnChange={setTopicForFilters}
                    placeholder={"physics"}
                    value={topicForFilters}
                  />
                </InputWrapper>
                <InputWrapper label="Приоритетный фильтр">
                  <ToggleBtn
                    isChecked={isPriorityFilter}
                    onClick={(e) =>
                      handleToggleButton(e.target.checked, setPriorityFilter)
                    }
                  />
                </InputWrapper>
                <InputWrapper label="Лектор">
                  <InputStandart
                    type="text"
                    placeholder="Имя Фамилия"
                    value={lecturer}
                    onChange={(e) => setLecturer(e.target.value)}
                    subtext="Лектор / Тип мероприятия / Возраст"
                  />
                </InputWrapper>
                <InputWrapper label="Стоимость">
                  <InputStandart
                    type="number"
                    placeholder="0"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    subtext="Оставить 0, если бесплатное"
                  />
                </InputWrapper>
                <InputWrapper label="Кол-во мест (цифра)">
                  <InputStandart
                    type="number"
                    placeholder="50"
                    value={totalAmount}
                    onChange={(e) => setTotalAmount(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Дата (гг-мм-дд / 2020-11-01)">
                  <InputStandart
                    type="text"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Время">
                  <InputStandart
                    type="text"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper label="Выберите файл">
                  <InputStandart type="file" onChange={uploadFileHandler} />
                  {uploading && <h6>Loading...</h6>}
                </InputWrapper>
                <InputWrapper label="Изображение">
                  <InputStandart
                    type="text"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                </InputWrapper>
                <div className="textarea-block">
                  <label htmlFor="">Описание</label>
                  <Editor
                    data={lection.description}
                    setEditorDescription={setEditorDescription}
                  />
                </div>
              </div>
              <button className="btn-x1 btn-green-fill" type="submit">
                Редактировать
              </button>
            </form>
          )}
        </div>
      </section>
    </main>
  );
}

export default UpdateLection;
