import api from "helpers/api";

import {
  PAYMENT_CREATE_REQUEST,
  PAYMENT_CREATE_SUCCESS,
  PAYMENT_CREATE_FAIL,
  PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_FAIL,
  PAYMENT_LIST_REQUEST,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAIL,
} from "сonstants/paymentConstants";

export const createPayment =
  (lectionID, amount) => async (dispatch, getState) => {
    try {
      dispatch({ type: PAYMENT_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await api.post(
        `/payment/${lectionID}?amount=${amount}`,
        {},
        config
      );

      dispatch({
        type: PAYMENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_CREATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// GET SUBSCRIPTION STATUS (PROTECTED)
export const getPaymentStatus = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_STATUS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // API REQUESTS
    const { data } = await api.get(`/payment/${id}`, config);

    dispatch({
      type: PAYMENT_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PAYMENT_STATUS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

// GET PAYMENTS LIST
export const getPaymentsList =
  (filter, limit, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({ type: PAYMENT_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUESTS
      const payments = await api.get(
        `/payment/all/payments?${filter ? `filter=${filter}` : ""}${
          limit ? `&limit=${limit}` : ""
        }&sort=-createdAt${pageNumber ? `&page=${pageNumber} : ` : ""}`,
        config
      );

      dispatch({
        type: PAYMENT_LIST_SUCCESS,
        payload: payments.data,
      });
    } catch (error) {
      dispatch({
        type: PAYMENT_LIST_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
