import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// STUB PAGES
import NotFound from "./pages/Stubs/NotFound";
import ServerError from "./pages/Stubs/ServerError";
import EmailConfirmed from "./pages/Stubs/EmailConfirmed";

// PUBLIC PAGES
import WorkerSingle from "./pages/Workers/WorkerSingle";
import LectionSingle from "./pages/Lectorium/LectionSingle";

// JOBS PAGES
import JobsPage from "./pages/Jobs/Jobs";
import JobConsultant from "./pages/Jobs/JobConsultant";
import JobCook from "./pages/Jobs/JobCook";
import JobBarista from "./pages/Jobs/JobBarista";
import Job1CProgrammer from "./pages/Jobs/Job1CProgrammer";
import JobSouvenirDesigner from "./pages/Jobs/JobSouvenirDesigner";
import JobCopywriter from "./pages/Jobs/JobCopywriter";
import JobPhotographer from "./pages/Jobs/JobPhotographer";
import JobChancellery from "./pages/Jobs/JobChancellery";
import JobBrandManager from "./pages/Jobs/JobBrandManager";

// AUTH PAGES
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import CreateNewPassword from "./pages/Auth/NewPassword";

// USER PAGES
import Profile from "./pages/User/Profile";

// ADMIN PAGES
import DashboardPage from "./pages/Admin/Dashboard";
import AdminLections from "./pages/Admin/Lections/Lections";
import LectionVisitors from "./pages/Admin/Lections/Visitors";
import LectionsArchive from "./pages/Admin/Lections/Archive";
import LectionPayments from "./pages/Admin/Lections/Payments";
import ArchiveSingleLection from "./pages/Admin/Lections/ArchiveSingle";
import EditLection from "./pages/Admin/Lections/Edit";
import AdminWorkers from "./pages/Admin/Workers/Workers";
import EditWorker from "./pages/Admin/Workers/Edit";
import UsersList from "./pages/Admin/Users/Users";
import VisitorsTracker from "./pages/Admin/Visitors/VisitorsTracker";
import AdminAnalytic from "./pages/Admin/Analytic/Analytic";

// PUBLIC PAGES WITH LAZY LOAD
const WelcomePage = lazy(() => import("./pages/Welcome/Welcome"));
const CoffeePage = lazy(() => import("./pages/Coffee/CoffeePage"));
const LectionsPage = lazy(() => import("./pages/Lectorium/Lections"));

// ADMIN PAGES WITH WITH LAZY LOAD
const LectionsAnalytic = lazy(() => import("./pages/Admin/Analytic/Lections"));
const ShopAnalytic = lazy(() => import("./pages/Admin/Analytic/Visitors"));

const ROUTES = [
  {
    path: "/",
    key: "ROOT",
    exact: true,
    component: WelcomePage,
  },
  {
    path: "/coffee",
    key: "COFFEE",
    component: RenderRoutes,
    routes: [
      {
        path: "/coffee",
        key: "COFFEE_ROOT",
        exact: true,
        component: CoffeePage,
      },
    ],
  },
  {
    path: "/lectorium",
    key: "LECTORIUM",
    component: RenderRoutes,
    routes: [
      {
        path: "/lectorium",
        key: "LECTORIUM_ROOT",
        exact: true,
        component: LectionsPage,
      },
      {
        path: "/lectorium/:id",
        key: "LECTORIUM_SINGLE_PAGE",
        exact: true,
        component: LectionSingle,
      },
    ],
  },
  {
    path: "/workers/:id",
    key: "WORKERS_SINGLE_PAGE",
    exact: true,
    component: WorkerSingle,
  },
  {
    path: "/jobs",
    key: "JOBS",
    component: RenderRoutes,
    routes: [
      {
        path: "/jobs",
        key: "JOBS_ROOT",
        exact: true,
        component: JobsPage,
      },
      {
        path: "/jobs/consultant",
        key: "JOBS_CONSULTANT_PAGE",
        exact: true,
        component: JobConsultant,
      },
      {
        path: "/jobs/cook",
        key: "JOBS_COOK_PAGE",
        exact: true,
        component: JobCook,
      },
      {
        path: "/jobs/barista",
        key: "JOBS_BARISTA_PAGE",
        exact: true,
        component: JobBarista,
      },
      {
        path: "/jobs/souvenir-designer",
        key: "JOBS_SOUVENIRDESIGNER_PAGE",
        exact: true,
        component: JobSouvenirDesigner,
      },
      {
        path: "/jobs/1c-programmer",
        key: "JOBS_1CPROGRAMMER_PAGE",
        exact: true,
        component: Job1CProgrammer,
      },
      {
        path: "/jobs/copywriter",
        key: "JOBS_COPYWRITER_PAGE",
        exact: true,
        component: JobCopywriter,
      },
      {
        path: "/jobs/photographer",
        key: "JOBS_PHOTOGRAPHER_PAGE",
        exact: true,
        component: JobPhotographer,
      },
      {
        path: "/jobs/chancellery",
        key: "JOBS_CHANCELLERY_PAGE",
        exact: true,
        component: JobChancellery,
      },
      {
        path: "/jobs/souvenir-brand",
        key: "JOBS_SOUVENIRBRAND_PAGE",
        exact: true,
        component: JobBrandManager,
      },
    ],
  },
  {
    path: "/auth",
    key: "AUTH",
    component: RenderRoutes,
    routes: [
      {
        path: "/auth/login",
        key: "AUTH_LOGIN",
        exact: true,
        restrictedToAuthorized: true,
        component: Login,
      },
      {
        path: "/auth/register",
        key: "AUTH_REGISTER",
        exact: true,
        restrictedToAuthorized: true,
        component: Register,
      },

      {
        path: "/auth/forgotpassword",
        key: "AUTH_FORGOT_PASSWORD",
        exact: true,
        restrictedToAuthorized: true,
        component: ForgotPassword,
      },

      {
        path: "/auth/restorepassword/:token",
        key: "AUTH_CREATE_NEW_PASSWORD",
        exact: true,
        restrictedToAuthorized: true,
        component: CreateNewPassword,
      },
    ],
  },
  {
    path: "/user",
    key: "USER",
    limited: true,
    role: ["user", "admin"],
    component: RenderRoutes,
    routes: [
      {
        path: "/user/profile",
        key: "USER_PROFILE",
        exact: true,
        component: Profile,
      },
    ],
  },
  {
    path: "/admin",
    key: "ADMIN",
    limited: true,
    role: ["admin"],
    component: RenderRoutes,
    routes: [
      {
        path: "/admin",
        key: "ADMIN_ROOT",
        exact: true,
        component: DashboardPage,
      },
      {
        path: "/admin/lections",
        key: "ADMIN_LECTIONS",
        component: RenderRoutes,
        routes: [
          {
            path: "/admin/lections",
            key: "ADMIN_LECTIONS_ROOT",
            exact: true,
            component: AdminLections,
          },
          {
            path: "/admin/lections/:id",
            key: "ADMIN_LECTION_VISITORS",
            exact: true,
            component: LectionVisitors,
          },
          {
            path: "/admin/lections/:id/edit",
            key: "ADMIN_LECTION_EDIT",
            exact: true,
            component: EditLection,
          },
          {
            path: "/admin/lections/archive/all",
            key: "ADMIN_LECTIONS_ARCHIVE",
            exact: true,
            component: LectionsArchive,
          },
          {
            path: "/admin/lections/archive/:id",
            key: "ADMIN_SINGLE_LECTION_ARCHIVE",
            exact: true,
            component: ArchiveSingleLection,
          },
          {
            path: "/admin/lections/payments/all",
            key: "ADMIN_LECTIONS_PAYMENTS",
            exact: true,
            component: LectionPayments,
          },
        ],
      },
      {
        path: "/admin/analytic",
        key: "ADMIN_ANALYTIC",
        component: RenderRoutes,
        routes: [
          {
            path: "/admin/analytic",
            key: "ADMIN_ANALYTIC_ROOT",
            exact: true,
            component: AdminAnalytic,
          },
          {
            path: "/admin/analytic/lections",
            key: "ADMIN_ANALYTIC_LECTIONS",
            exact: true,
            component: LectionsAnalytic,
          },
          {
            path: "/admin/analytic/shop",
            key: "ADMIN_ANALYTIC_SHOP",
            exact: true,
            component: ShopAnalytic,
          },
        ],
      },
      {
        path: "/admin/workers",
        key: "ADMIN_WORKERS",
        component: RenderRoutes,
        routes: [
          {
            path: "/admin/workers",
            key: "ADMIN_WORKERS_ROOT",
            exact: true,
            component: AdminWorkers,
          },
          {
            path: "/admin/workers/:id/edit",
            key: "ADMIN_WORKERS_EDIT",
            exact: true,
            component: EditWorker,
          },
        ],
      },
      {
        path: "/admin/users",
        key: "ADMIN_USERS",
        component: RenderRoutes,
        routes: [
          {
            path: "/admin/users",
            key: "ADMIN_USERS_ROOT",
            exact: true,
            component: UsersList,
          },
        ],
      },
      {
        path: "/admin/shop",
        key: "ADMIN_SHOP",
        component: RenderRoutes,
        routes: [
          {
            path: "/admin/shop/visitors",
            key: "ADMIN_SHOP_VISITORS",
            exact: true,
            component: VisitorsTracker,
          },
        ],
      },
    ],
  },
  {
    path: "/stubs",
    key: "STUBS",
    component: RenderRoutes,
    routes: [
      {
        path: "/stubs/email/alreadyconfirmed",
        key: "STUB_EMAILCONFIRMED",
        exact: true,
        component: EmailConfirmed,
      },
    ],
  },
  {
    path: "/errors",
    key: "ERRORS",
    component: RenderRoutes,
    routes: [
      {
        path: "/errors/404",
        key: "ERROR_NOTFOUND",
        exact: true,
        component: NotFound,
      },
      {
        path: "/errors/500",
        key: "ERROR_INTERNAL_SERVER",
        exact: true,
        component: ServerError,
      },
    ],
  },
];

export default ROUTES;

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export function RenderRoutes({ routes }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <Switch>
      {routes.map((route) => {
        if (route.limited && route.role) {
          if (userInfo && route.role.includes(userInfo.user.role)) {
            return <RouteWithSubRoutes key={route.key} {...route} />;
          }
          return <Redirect key={route.key} to={"/"} />;
        }
        if (route.restrictedToAuthorized && userInfo) {
          return <Redirect key={route.key} to={"/"} />;
        }
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={NotFound} />
    </Switch>
  );
}
