import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import { getPaymentsList } from "redux/actions/paymentActions";

// COMPONENT
import Spinner from "components/Loaders/Spinner";
import CardPayment from "components/cards/CardPayment";
import Pagination from "components/Buttons/Pagination";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "../components/SectionHeaderAdmin";

function LectionPayments() {
  const dispatch = useDispatch();

  const paymentsList = useSelector((state) => state.payment.list);
  const { loading, error, payments } = paymentsList;

  useEffect(() => {
    dispatch(getPaymentsList("", 10));
  }, [dispatch]);

  const handlePaymentsPagination = (page) => {
    dispatch(getPaymentsList("", 10, page));
    window.scrollTo(0, 0);
  };

  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Онлайн оплата" />
      <section className="section__admin--lections-payments">
        <div className="container">
          {loading || !payments.data ? (
            <Spinner />
          ) : error ? (
            <h6>{error}</h6>
          ) : (
            <>
              <div className="cards-wrapper">
                {payments.data
                  .filter(
                    (payment) =>
                      payment.status === "succeeded" ||
                      payment.status === "canceled" ||
                      payment.status === "refunded"
                  )
                  .map((payment, payment_key) => (
                    <CardPayment payment={payment} key={payment_key} />
                  ))}
              </div>
              <Pagination
                pages={payments.pagesTotal ? payments.pagesTotal : []}
                currentPage={payments.page ? payments.page : 1}
                action={handlePaymentsPagination}
              />
            </>
          )}
        </div>
      </section>
    </main>
  );
}

export default LectionPayments;
