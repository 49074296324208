import React from "react";
import { useHistory } from "react-router-dom";

function Back() {
  let history = useHistory();
  return (
    <div className="button__history--back" onClick={() => history.goBack()}>
      <button>
        <i className="far fa-long-arrow-left"></i> Назад
      </button>
    </div>
  );
}

export default Back;
