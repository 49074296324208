import React from "react";
import { Link } from "react-router-dom";
import { checkDateLength, getRusMonthDative } from "../../middleware/dates";

// COMPONENTS
import Label from "../Label/Label";

function CardEvent({ event }) {
  const { _id, title, date, price, startTime, lecturer, image } = event;

  const newDate = new Date();
  const currentDate = `${newDate.getFullYear()}-${checkDateLength(
    newDate.getMonth() + 1
  )}-${checkDateLength(newDate.getDate())}`;

  return (
    <Link to={`/lectorium/${_id}`} className="card-event">
      <div className="card-image">
        <img rel="preload" src={image} alt={title} />
      </div>
      {price > 0 ? (
        <div className="card-price">
          <p>{price} ₽</p>
        </div>
      ) : null}
      {lecturer.split("/")[1] !== " " ? (
        <div className="card-category">
          <Label
            styles={"label--medium label--black"}
            text={lecturer.split("/")[1]}
          />
        </div>
      ) : null}
      <h5 className="card-title">
        {title} {lecturer ? `/ ${lecturer.split("/")[2]}` : null}
      </h5>
      <div className="card-date">
        <p>
          {currentDate === date
            ? `Сегодня в ${startTime.substring(0, 5)}`
            : `${date.slice(8, 10)} ${getRusMonthDative(
                Number(date.slice(5, 7))
              ).toLowerCase()} в ${startTime.substring(0, 5)}`}
        </p>
      </div>
    </Link>
  );
}

export default CardEvent;
