import React, { useState } from "react";
import { useDispatch } from "react-redux";

// CONSTANTS
import { UI_NOTIFICATION_SHOW } from "сonstants/uiConstants";

// ACTIONS
import { forgotPassword } from "redux/actions/userActions";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

// LOCAL COMPONENTS
import AuthInput from "./components/Input";

function ForgotPassword({ history }) {
  const dispatch = useDispatch();

  // LOCAL STATE
  const [email, setEmail] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      const notifications = [
        {
          type: "slide",
          color: "#ff6c65",
          title: "Заполните email",
          icon: "fal fa-exclamation-circle",
        },
      ];
      return dispatch({
        type: UI_NOTIFICATION_SHOW,
        payload: notifications,
      });
    }
    dispatch(forgotPassword(email, history));
  };

  return (
    <main className="margined-navbar">
      <section className="section--auth-forgot-password">
        <div className="container">
          <div className="content-bar">
            <BackPageBtn />
          </div>
          <div className="auth-container">
            <div className="column">
              <div className="column-header">
                <div className="icon">
                  <i className="far fa-unlock-alt"></i>
                </div>
                <div className="auth-links">
                  <p className="active-auth-link">Сброс Пароля</p>
                </div>
              </div>
              <form onSubmit={handleFormSubmit} className="auth-form">
                <small className="auth-small-text">
                  Мы отправим Вам инструкцию для создания нового пароля
                </small>
                <AuthInput
                  icon="far fa-envelope"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />

                <button className="btn btn-x1 btn-green-fill" type="submit">
                  Отправить
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ForgotPassword;
