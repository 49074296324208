import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// UI
import {
  sidebarReducer,
  overlayReducer,
  notificationReducer,
  modalReducer,
} from "./reducers/uiReducer";

// LECTIONS
import {
  lectionListReducer,
  lectionFiltersReducer,
  lectionDetailsReducer,
  lectionDeleteReducer,
  lectionCreateReducer,
  lectionUpdateReducer,
  lectionVisitorsReducer,
  lectionVisitorCheckReducer,
  lectionsArchieveReducer,
  lectionArchiveDetailsReducer,
  lectionArchiveTransfer,
} from "./reducers/lectionReducers";

// SUBSCRIBE
import {
  subscribeLectionReducer,
  unsubscribeLectionReducer,
  subscriptionStatus,
} from "./reducers/subscribeReducer";

// CONSULTANTS
import {
  workersListReducer,
  workerDetailsReducer,
  workerCreateReducer,
  workerUpdateReducer,
  workerDeleteReducer,
} from "./reducers/workerReducers";

// USERS
import {
  userLoginReducer,
  userRegisterReducer,
  currentUserReducer,
  usersListReducer,
} from "./reducers/userReducers";

// STATS
import {
  usersStatsReducer,
  lectionsStatsReducer,
} from "./reducers/statReducer";

// VISITORS
import {
  visitorsListReducer,
  visitorCreateReducer,
} from "./reducers/visitorReducer";

// PAYMENT
import {
  paymentCreateReducer,
  paymentStatusReducer,
  paymentListReducer,
} from "./reducers/paymentReducers";

const reducer = combineReducers({
  lections: combineReducers({
    lectionsList: lectionListReducer,
    lectionFilters: lectionFiltersReducer,
    lectionDetails: lectionDetailsReducer,
    lectionCreate: lectionCreateReducer,
    lectionUpdate: lectionUpdateReducer,
    lectionDelete: lectionDeleteReducer,
    lectionVisitors: lectionVisitorsReducer,
    lectionVisitorCheck: lectionVisitorCheckReducer,
    lectionsArchieve: lectionsArchieveReducer,
    lectionArchiveDetails: lectionArchiveDetailsReducer,
    lectionTransfer: lectionArchiveTransfer,
  }),
  workers: combineReducers({
    workersList: workersListReducer,
    workerDetails: workerDetailsReducer,
    workerCreate: workerCreateReducer,
    workerUpdate: workerUpdateReducer,
    workerDelete: workerDeleteReducer,
  }),
  ui: combineReducers({
    sidebar: sidebarReducer,
    overlay: overlayReducer,
    notifications: notificationReducer,
    modal: modalReducer,
  }),
  stats: combineReducers({
    usersStats: usersStatsReducer,
    lectionsStats: lectionsStatsReducer,
  }),
  subscription: combineReducers({
    status: subscriptionStatus,
    subscribeLection: subscribeLectionReducer,
    unsubscribeLection: unsubscribeLectionReducer,
  }),
  payment: combineReducers({
    create: paymentCreateReducer,
    status: paymentStatusReducer,
    list: paymentListReducer,
  }),
  currentUser: currentUserReducer,
  visitorCreate: visitorCreateReducer,
  visitorsList: visitorsListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  users: usersListReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
