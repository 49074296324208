import React from "react";
import { Link } from "react-router-dom";

// LOCAL COMPONENTS
import SectionHeaderAdmin from "./components/SectionHeaderAdmin";

function DashboardPage() {
  return (
    <main className="margined-navbar">
      <SectionHeaderAdmin title="Панель администратора" />
      <section className="section--admin-actions">
        <div className="container">
          <div className="cards-wrapper">
            <Link to="/admin/lections" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-projector"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Управление Лекторием</h6>
            </Link>
            <Link to="/admin/workers" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-briefcase"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Управление Сотрудниками</h6>
            </Link>
            <Link to="/admin/users" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-user-edit"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Управление пользователями</h6>
            </Link>
            <Link to="/admin/shop/visitors" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-users"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Учет посетителей</h6>
            </Link>
            <Link to="/admin/analytic" className="card-action-admin">
              <div className="card-icon">
                <i className="fal fa-chart-bar"></i>
              </div>
              <div className="card-icon-link">
                <i className="fal fa-arrow-right"></i>
              </div>
              <h6 className="card-title">Аналитика</h6>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default DashboardPage;
