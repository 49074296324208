import api from "helpers/api";

import {
  VISITORS_GET_REQUEST,
  VISITORS_GET_SUCCESS,
  VISITORS_GET_FAIL,
  VISITOR_CREATE_REQUEST,
  VISITOR_CREATE_SUCCESS,
  VISITOR_CREATE_FAIL,
} from "сonstants/visitorsConstants";

// GET VISITORS (ADMIN)
export const listVisitors =
  (startPeriod, endPeriod) => async (dispatch, getState) => {
    try {
      dispatch({ type: VISITORS_GET_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUESTS
      const { data } = await api.get(
        `/visitors/?startPeriod=${startPeriod}&endPeriod=${endPeriod}`,
        config
      );

      dispatch({
        type: VISITORS_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VISITORS_GET_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };

// CREATE VISITOR (ADMIN)
export const createVisitor =
  (title, actionType, visitorType) => async (dispatch, getState) => {
    try {
      dispatch({ type: VISITOR_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // API REQUESTS
      const { data } = await api.post(
        "/visitors",
        { title, actionType, visitorType },
        config
      );

      dispatch({
        type: VISITOR_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VISITOR_CREATE_FAIL,
        payload:
          error.response && error.response.data.error
            ? error.response.data.error
            : error.error,
      });
    }
  };
