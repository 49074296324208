import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// MIDDLEWARE
import { handleSmoothScroll } from "../../middleware/smoothScroll";

// ACTIONS
import { logout } from "../../redux/actions/userActions";
import { handleSidebar } from "../../redux/actions/uiActions";

// IMAGES
import LogoSidebar from "../../images/svg/logo-navbar.svg";

function Sidebar() {
  const location = useLocation();
  const dispatch = useDispatch();

  // REDUX STATE
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sidebar = useSelector((state) => state.ui.sidebar);
  const { isOpen } = sidebar;

  const handleSidebarLink = () => {
    dispatch(handleSidebar(false));
  };

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <aside className={`sidebar ${isOpen ? "sidebar--opened" : null}`}>
      <div className="content__wrapper">
        <div className="sidebar__logo">
          <img src={LogoSidebar} alt="Логотип" />
        </div>
        <div className="sidebar__links">
          {location.pathname === "/" ? (
            ""
          ) : (
            <div className="link">
              <Link to="/" onClick={handleSidebarLink}>
                Главн<span className="fix-font">а</span>я
              </Link>
            </div>
          )}
          {location.pathname === "/" ? (
            <div className="link">
              <span
                onClick={() => handleSmoothScroll("#books", handleSidebarLink)}
              >
                Книги
              </span>
            </div>
          ) : (
            <div className="link">
              <Link to={"/#books"} onClick={handleSidebarLink}>
                Книги
              </Link>
            </div>
          )}
          {location.pathname === "/" ? (
            <div className="link">
              <span
                onClick={() => handleSmoothScroll("#coffee", handleSidebarLink)}
              >
                Кофе
              </span>
            </div>
          ) : (
            <div className="link">
              <Link to={"/coffee"} onClick={handleSidebarLink}>
                Кофе
              </Link>
            </div>
          )}
          <div className="link">
            <Link to={"/lectorium"} onClick={handleSidebarLink}>
              Др. И<span className="fix-font">з</span>мерения
            </Link>
          </div>
          {userInfo ? (
            <div className="link">
              <Link to={"/user/profile"} onClick={handleSidebarLink}>
                Профиль
              </Link>
            </div>
          ) : null}
          {userInfo && userInfo.user.role === "admin" ? (
            <div className="link">
              <Link to="/admin" onClick={handleSidebarLink}>
                Админ
              </Link>
            </div>
          ) : null}
          {userInfo ? (
            <div className="link" onClick={handleLogout}>
              <span>
                <i className="fal fa-sign-out-alt"></i> Выход
              </span>
            </div>
          ) : (
            <div className="link">
              <Link to="/auth/login" onClick={handleSidebarLink}>
                Войти / Регистрация
              </Link>
            </div>
          )}
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
