import React from "react";

function NotFound() {
  return (
    <main className="margined-navbar">
      <section className="section__stub--notfound">
        <div className="container">
          <div className="stub-wrapper">
            <i className="fal fa-exclamation-circle"></i>
            <h1>Страница не найдена</h1>
          </div>
        </div>
      </section>
    </main>
  );
}

export default NotFound;
