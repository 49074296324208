import React from "react";

function AmountSelector({ amount, changeAmount, maxValue, minValue }) {
  const handleAmountSelect = (action) => {
    if (action === "increase") {
      if (amount === maxValue) {
        return amount;
      } else {
        changeAmount(amount + 1);
      }
    }
    if (action === "decrease") {
      if (amount === minValue) {
        return amount;
      } else {
        changeAmount(amount - 1);
      }
    }
  };
  return (
    <div className="amount__selector">
      <button
        className="amount__selector--button"
        onClick={() => handleAmountSelect("decrease")}
        aria-label="Убавить кол-во человек"
      >
        <i className="fal fa-minus"></i>
      </button>
      <div className="amount__selector--value">{amount}</div>
      <button
        className="amount__selector--button"
        onClick={() => handleAmountSelect("increase")}
        aria-label="Добавить кол-во человек"
      >
        <i className="fal fa-plus"></i>
      </button>
    </div>
  );
}

export default AmountSelector;
