import {
  LECTION_LIST_REQUEST,
  LECTION_LIST_SUCCESS,
  LECTION_LIST_FAIL,
  LECTION_FILTERS_REQUEST,
  LECTION_FILTERS_SUCCESS,
  LECTION_FILTERS_FAIL,
  LECTION_DETAILS_REQUEST,
  LECTION_DETAILS_SUCCESS,
  LECTION_DETAILS_FAIL,
  LECTION_DETAILS_RESET,
  LECTION_DELETE_REQUEST,
  LECTION_DELETE_SUCCESS,
  LECTION_DELETE_FAIL,
  LECTION_CREATE_REQUEST,
  LECTION_CREATE_SUCCESS,
  LECTION_CREATE_FAIL,
  LECTION_CREATE_RESET,
  LECTION_UPDATE_REQUEST,
  LECTION_UPDATE_SUCCESS,
  LECTION_UPDATE_FAIL,
  LECTION_UPDATE_RESET,
  LECTION_VISITORS_REQUEST,
  LECTION_VISITORS_SUCCESS,
  LECTION_VISITORS_FAIL,
  LECTION_VISITORS_RESET,
  LECTION_CHECK_VISITOR_REQUEST,
  LECTION_CHECK_VISITOR_SUCCESS,
  LECTION_CHECK_VISITOR_FAIL,
  LECTION_ARCHIEVE_REQUEST,
  LECTION_ARCHIEVE_SUCCESS,
  LECTION_ARCHIEVE_FAIL,
  LECTION_SINGLE_ARCHIVE_REQUEST,
  LECTION_SINGLE_ARCHIVE_SUCCESS,
  LECTION_SINGLE_ARCHIVE_FAIL,
  LECTION_SINGLE_ARCHIVE_RESET,
  LECTION_ARCHIVE_TRANSFER_REQUEST,
  LECTION_ARCHIVE_TRANSFER_SUCCESS,
  LECTION_ARCHIVE_TRANSFER_FAIL,
  LECTION_ARCHIVE_TRANSFER_RESET,
} from "сonstants/lectionConstants";

// ALL LECTIONS
export const lectionListReducer = (state = { lections: [] }, action) => {
  switch (action.type) {
    case LECTION_LIST_REQUEST:
      return { loading: true };
    case LECTION_LIST_SUCCESS:
      return {
        loading: false,
        lections: action.payload.data,
      };
    case LECTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ALL LECTION FILTERS
export const lectionFiltersReducer = (state = { filters: [] }, action) => {
  switch (action.type) {
    case LECTION_FILTERS_REQUEST:
      return { loading: true, ...state };
    case LECTION_FILTERS_SUCCESS:
      return {
        loading: false,
        filters: action.payload.data,
      };
    case LECTION_FILTERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// SINGLE LECTION
export const lectionDetailsReducer = (state = { lection: {} }, action) => {
  switch (action.type) {
    case LECTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case LECTION_DETAILS_SUCCESS:
      return { loading: false, lection: action.payload };
    case LECTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LECTION_DETAILS_RESET:
      return { lection: {} };
    default:
      return state;
  }
};

// DELETE LECTION (ADMIN)
export const lectionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LECTION_DELETE_REQUEST:
      return { loading: true };
    case LECTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case LECTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// CREATE LECTION (ADMIN)
export const lectionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LECTION_CREATE_REQUEST:
      return { loading: true };
    case LECTION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        lection: action.payload.data,
      };
    case LECTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case LECTION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// UPDATE LECTION (ADMIN)
export const lectionUpdateReducer = (state = { lection: {} }, action) => {
  switch (action.type) {
    case LECTION_UPDATE_REQUEST:
      return { loading: true };
    case LECTION_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        lection: action.payload.data,
      };
    case LECTION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case LECTION_UPDATE_RESET:
      return { lection: {} };
    default:
      return state;
  }
};

// VISITORS GET SINGLE LECTION (ADMIN)
export const lectionVisitorsReducer = (
  state = { subscriptions: [], payments: [] },
  action
) => {
  switch (action.type) {
    case LECTION_VISITORS_REQUEST:
      return { loading: true, ...state };
    case LECTION_VISITORS_SUCCESS:
      return {
        loading: false,
        subscriptions: action.payload.subscriptions,
        payments: action.payload.payments,
      };
    case LECTION_VISITORS_FAIL:
      return { loading: false, error: action.payload };
    case LECTION_VISITORS_RESET:
      return { subscriptions: [], payments: [] };
    default:
      return state;
  }
};

export const lectionVisitorCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case LECTION_CHECK_VISITOR_REQUEST:
      return { loading: true };
    case LECTION_CHECK_VISITOR_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case LECTION_CHECK_VISITOR_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const lectionsArchieveReducer = (state = { lections: [] }, action) => {
  switch (action.type) {
    case LECTION_ARCHIEVE_REQUEST:
      return { loading: true, ...state };
    case LECTION_ARCHIEVE_SUCCESS:
      return {
        loading: false,
        lections: action.payload,
      };
    case LECTION_ARCHIEVE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const lectionArchiveDetailsReducer = (
  state = { lection: {} },
  action
) => {
  switch (action.type) {
    case LECTION_SINGLE_ARCHIVE_REQUEST:
      return { loading: true, ...state };
    case LECTION_SINGLE_ARCHIVE_SUCCESS:
      return { loading: false, lection: action.payload };
    case LECTION_SINGLE_ARCHIVE_FAIL:
      return { loading: false, error: action.payload };
    case LECTION_SINGLE_ARCHIVE_RESET:
      return { lection: {} };
    default:
      return state;
  }
};

export const lectionArchiveTransfer = (state = {}, action) => {
  switch (action.type) {
    case LECTION_ARCHIVE_TRANSFER_REQUEST:
      return { loading: true };
    case LECTION_ARCHIVE_TRANSFER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case LECTION_ARCHIVE_TRANSFER_FAIL:
      return { loading: false, error: action.payload };
    case LECTION_ARCHIVE_TRANSFER_RESET:
      return {};
    default:
      return state;
  }
};
