import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import { getUser } from "redux/actions/userActions";

// COMPONENTS
import Meta from "components/Meta/Meta";
import Spinner from "components/Loaders/Spinner";
import BackPageBtn from "components/Buttons/PrevPage";
import CardEvent from "components/cards/CardEvent";
import CardPayment from "components/cards/CardPayment";

function ProfilePage() {
  const dispatch = useDispatch();

  // GET LOGGED USER INFO FROM STATE
  const currentUser = useSelector((state) => state.currentUser);
  const { userInfo: user } = currentUser;

  // LOCAL STATE
  const [activeButton, setActiveButton] = useState("events");

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const handleActiveButton = (type) => {
    setActiveButton(type);
  };

  return (
    <>
      <Meta title={"Профиль — Книги, кофе и другие измерения"} />
      <main className="margined-navbar">
        {!user ? (
          <Spinner />
        ) : (
          <>
            <section className="section--profile__actions-info">
              <div className="container">
                <div className="content-bar">
                  <BackPageBtn />
                </div>
                <div className="profile__topbar">
                  <div className="profile__actions">
                    <button
                      className={`button__action ${
                        activeButton === "events" ? "button--active" : null
                      }`}
                      onClick={() => handleActiveButton("events")}
                    >
                      <i className="fal fa-calendar-alt"></i>
                      Мероприятия
                    </button>
                    <button
                      className={`button__action ${
                        activeButton === "payments" ? "button--active" : null
                      }`}
                      onClick={() => handleActiveButton("payments")}
                    >
                      <i className="fal fa-credit-card"></i>Платежи
                    </button>
                  </div>
                  <div className="profile--information">
                    <div className="profile--text">
                      <p className="profile--name">
                        {user.name} {user.surname}
                      </p>
                      <p className="profile--email">{user.email}</p>
                    </div>
                    <div className="profile--avatar">
                      <i className="far fa-user"></i>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {activeButton === "events" ? (
              <section className="section--profile__incoming-events">
                <div className="container">
                  <h6 className="incoming__events--title">
                    Предстоящие мероприятия
                  </h6>
                  <div className="cards-wrapper">
                    {user.lections
                      .sort(
                        (a, b) =>
                          new Date(a.dateWithTime) - new Date(b.dateWithTime)
                      )
                      .map((lection) => (
                        <CardEvent key={lection._id} event={lection} />
                      ))}
                  </div>
                </div>
              </section>
            ) : (
              <section className="section__profile--payments">
                <div className="container">
                  <h6 className="incoming__events--title">Платежи</h6>
                  <div className="cards-wrapper">
                    {user.payments
                      .filter(
                        (payment) =>
                          payment.status === "succeeded" ||
                          payment.status === "canceled" ||
                          payment.status === "refunded"
                      )
                      .sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      )
                      .map((payment, payment_key) => (
                        <CardPayment payment={payment} key={payment_key} />
                      ))}
                  </div>
                </div>
              </section>
            )}
          </>
        )}
      </main>
    </>
  );
}

export default ProfilePage;
