import {
  WORKERS_LIST_REQUEST,
  WORKERS_LIST_SUCCESS,
  WORKERS_LIST_FAIL,
  WORKER_DETAILS_REQUEST,
  WORKER_DETAILS_SUCCESS,
  WORKER_DETAILS_FAIL,
  WORKER_DETAILS_RESET,
  WORKER_CREATE_REQUEST,
  WORKER_CREATE_SUCCESS,
  WORKER_CREATE_FAIL,
  WORKER_CREATE_RESET,
  WORKER_UPDATE_REQUEST,
  WORKER_UPDATE_SUCCESS,
  WORKER_UPDATE_FAIL,
  WORKER_UPDATE_RESET,
  WORKER_DELETE_REQUEST,
  WORKER_DELETE_SUCCESS,
  WORKER_DELETE_FAIL,
} from "сonstants/workerConstants";

// ALL WORKERS
export const workersListReducer = (
  state = { workers: [], filters: [] },
  action
) => {
  switch (action.type) {
    case WORKERS_LIST_REQUEST:
      return { loading: true, ...state };
    case WORKERS_LIST_SUCCESS:
      return {
        loading: false,
        workers: action.payload.workers.data,
        filters: action.payload.filters.data,
      };
    case WORKERS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// SINGLE WORKER
export const workerDetailsReducer = (state = { worker: {} }, action) => {
  switch (action.type) {
    case WORKER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case WORKER_DETAILS_SUCCESS:
      return { loading: false, worker: action.payload };
    case WORKER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case WORKER_DETAILS_RESET:
      return { worker: {} };
    default:
      return state;
  }
};

// CREATE WORKER (ADMIN)
export const workerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_CREATE_REQUEST:
      return { loading: true };
    case WORKER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        worker: action.payload.data,
      };
    case WORKER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WORKER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

// UPDATE WORKER (ADMIN)
export const workerUpdateReducer = (state = { worker: {} }, action) => {
  switch (action.type) {
    case WORKER_UPDATE_REQUEST:
      return { loading: true };
    case WORKER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        worker: action.payload.data,
      };
    case WORKER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case WORKER_UPDATE_RESET:
      return { worker: {} };
    default:
      return state;
  }
};

// DELETE WORKER (ADMIN)
export const workerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WORKER_DELETE_REQUEST:
      return { loading: true };
    case WORKER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case WORKER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
