import React from "react";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobConsultant() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-single">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Резидент книжного магазина</h4>
          <div className="content">
            <p className="font-bold">Добрый день!</p>
            <p>
              Сперва может показаться, что эта вакансия для продавцов. И отчасти
              это действительно так, но только отчасти. В первую очередь мы ищем
              специалистов в определенных сферах, людей, заинтересованных в
              развитии любимой отрасли.
            </p>
            <p className="font-bold">
              Мы ищем в книжный магазин резидентов по:
            </p>
            <li>
              — естественным наукам (физика, химия, геология, астрономия и пр);
            </li>
            <li>
              — русской и зарубежной литературе, разбирающихся в
              литературоведении);
            </li>
            <li>
              {" "}
              — социальным наукам (философия, культурология, экономика и тп{")"}
              ;
            </li>
            <li>
              — детским книгам, особенно полезно умение общаться с детьми,
              занимать их и развлекать;
            </li>
            <li>
              — искусству: не только по живописи, но и музыке, театру, дизайну,
              стритарту, комиксам и тп;
            </li>
            <li>
              — религиоведению, мифологии, мистике и эзотерики, нами
              приветствуется только научный критический подход;
            </li>
            <li>
              — досуг: путешествия, спорт, коллекционирование, кулинария, тут
              нам пригодятся разносторонние личности;
            </li>
            <p>
              Без этих разделов невозможно представить независимый книжный
              магазин. Мы закупили немало книг по этим направлениям и продолжаем
              поиск достойных людей, готовых их развивать.
            </p>
            <p className="font-bold">Вы нам подходите, если:</p>
            <li>— увлечены одной из вышеуказанных сфер;</li>
            <li>
              — постоянно в поиске новой информации в различных источниках — от
              книг до роликов на ютубе и телеграм-каналов;
            </li>
            <li>— хотите общаться с людьми, умеете их заинтересовать.</li>
            <p className="font-bold">Приветствуются:</p>
            <li>— профильное образование в сфере, которой вы увлечены;</li>
            <li>— харизма, грамотная речь;</li>
            <li>— умение писать и вести соцсети.</li>
            <p className="font-bold">Что надо делать:</p>
            <li>— ходить к нам на работу хотя бы на несколько часов в день;</li>
            <li>
              — увлеченно общаться с посетителями без навязчивости и назидания;
            </li>
            <li>
              — в отсутствие посетителей читать любимые книги или заниматься их
              выкладкой;
            </li>
            <li>
              — поддерживать порядок в пространстве, участвовать в закупках,
              инвентаризациях и прочих рабочих процессах проекта.
            </li>
            <p className="font-bold">Что мы предлагаем:</p>
            <li>
              — работа в интересном проекте среди единомышленников; красивое
              здание, вкусный кофе, регулярные мероприятия;
            </li>
            <li>— гибкий посменный график;</li>
            <li>— развоз до работы и обратно;</li>
            <li>
              — белый оклад и не менее белая премия по результатам продаж 2 раза
              в месяц без задержек;
            </li>
            <li>— скидки на книги, бесплатный кофе.</li>
            <p>
              Для начала, мы примем вас на должность младшего научного
              специалиста на ставку 800 рублей за восьмичасовую смену, или 100
              рублей в час.
            </p>
            <p>
              Ваш рабочий день будет состоять из работы в зале и работы в
              архиве. Архивная работа — это помощь с заказом новых книг и разбор
              поставок. По окончании испытательного срока активные участники
              жизни пространства будут посвящены в резиденты с прибавкой к
              зарплате и должностным обязанностям.
            </p>
            <p>
              Сейчас вам достаточно написать на электронную почту
              <a href="mailto: labor@drugie-knigi.ru">
                {" "}
                labor@drugie-knigi.ru
              </a>{" "}
              с темой письма «Кандидат в резиденты». В ответ мы отправим вам
              небольшую анкету. Если от нас не будет ответа больше трех дней,
              проверьте папку «Спам», а потом продублируйте ваше письмо.
            </p>
            <p>
              Если и это не помогло, напишите нам в соцсетях или позвоните по
              телефону <a href="tel:+79122000199">+79122000199</a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobConsultant;
