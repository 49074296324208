import {
  UI_SIDEBAR_OPEN,
  UI_SIDEBAR_CLOSE,
  UI_OVERLAY_ACTIVE,
  UI_OVERLAY_DISABLED,
  UI_NOTIFICATION_SHOW,
  UI_NOTIFICATION_HIDE,
  UI_MODAL_SHOW,
  UI_MODAL_HIDE,
} from "сonstants/uiConstants";

// SIDEBAR
export const sidebarReducer = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case UI_SIDEBAR_OPEN:
      return { isOpen: action.payload };
    case UI_SIDEBAR_CLOSE:
      return {
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export const overlayReducer = (state = { isActive: false }, action) => {
  switch (action.type) {
    case UI_OVERLAY_ACTIVE:
      return { isActive: action.payload };
    case UI_OVERLAY_DISABLED:
      return {
        isActive: action.payload,
      };
    default:
      return state;
  }
};

export const notificationReducer = (state = { notifications: [] }, action) => {
  switch (action.type) {
    case UI_NOTIFICATION_SHOW:
      return {
        notifications: action.payload,
      };
    case UI_NOTIFICATION_HIDE:
      return { notifications: [] };
    default:
      return state;
  }
};

export const modalReducer = (state = { isActive: false }, action) => {
  switch (action.type) {
    case UI_MODAL_SHOW:
      return { isActive: action.payload };
    case UI_MODAL_HIDE:
      return {
        isActive: action.payload,
      };
    default:
      return state;
  }
};
