import React from "react";
import { Link } from "react-router-dom";

// COMPONENTS
import BackPageBtn from "components/Buttons/PrevPage";

function JobsPage() {
  return (
    <main className="margined-navbar">
      <section className="section--jobs-all">
        <div className="container">
          <div className="content-bar font-white">
            <BackPageBtn />
          </div>
          <h4>Вакансии</h4>
          <div className="cards-wrapper">
            <div className="card-job">
              <h5 className="card-title">Резидент книжного магазина</h5>
              <p className="card-description">
                Главная боевая единица нашего пространства, любит книги и умеет
                про них рассказывать без снобизма и назидания
              </p>
              <Link to="/jobs/consultant" className="btn btn-x2 btn-black-fill">
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">Бариста в кофейню</h5>
              <p className="card-description">
                Веселые и приветливые ребята, влюбленные в кофе.
              </p>
              <Link to="/jobs/barista" className="btn btn-x2 btn-black-fill">
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">Дизайнер сувениров</h5>
              <p className="card-description">
                Мы мечтаем развить линейку собственного мерча на культурную
                тематику, и даже сделать из этого отдельный проект
              </p>
              <Link
                to="/jobs/souvenir-designer"
                className="btn btn-x2 btn-black-fill"
              >
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">1C программист</h5>
              <p className="card-description">
                Маг, волшебник и просто хороший человек, способный укротить
                строптивый русский софт
              </p>
              <Link
                to="/jobs/1C-programmer"
                className="btn btn-x2 btn-black-fill"
              >
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">Копирайтер</h5>
              <p className="card-description">
                Чтобы мы не позорились своими текстами вакансий, постов и
                описаний мероприятий.
              </p>
              <Link to="/jobs/copywriter" className="btn btn-x2 btn-black-fill">
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">
                Фотограф-видеограф с навыками монтажа
              </h5>
              <p className="card-description">
                Потому что мы хотим выглядеть красиво, выкладывать наши лекции
                и, наконец, завести ютуб-канал
              </p>
              <Link
                to="/jobs/photographer"
                className="btn btn-x2 btn-black-fill"
              >
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">
                Продавец художественных и канцелярских товаров
              </h5>
              <p className="card-description">
                Мы хотим развить наш художественно-канцелярский отдел, чтобы он
                тоже стал лучшим в регионе, как и наш книжный.
              </p>
              <Link
                to="/jobs/chancellery"
                className="btn btn-x2 btn-black-fill"
              >
                Подробнее
              </Link>
            </div>
            <div className="card-job">
              <h5 className="card-title">
                Бренд-менеджер сувенирной продукции
              </h5>
              <p className="card-description">
                Мы начинаем активно развивать направление работы по производству
                и продаже тематической сувенирной продукции
              </p>
              <Link
                to="/jobs/souvenir-brand"
                className="btn btn-x2 btn-black-fill"
              >
                Подробнее
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default JobsPage;
