import React from "react";

import Loader from "../../images/gif/loader.gif";

function Spinner() {
  return (
    <div className="loader-spinner-wrapper">
      <img src={Loader} alt="Loader" />
    </div>
  );
}

export default Spinner;
