import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// CONSTANTS
import { UI_NOTIFICATION_SHOW } from "../сonstants/uiConstants";
import { SUBSCRIPTION_RESET } from "../сonstants/subscribeConstants";

function useSubscription({ getCurrentValue }) {
  const dispatch = useDispatch();

  // REDUX STATE
  const isSubscribedState = useSelector((state) => state.subscription.status);
  const { isSubscribed } = isSubscribedState;

  const subscribeLection = useSelector(
    (state) => state.subscription.subscribeLection
  );
  const { success: successSubscribe } = subscribeLection;

  const unsubscribeLection = useSelector(
    (state) => state.subscription.unsubscribeLection
  );
  const { success: successUnSubscribe } = unsubscribeLection;

  useEffect(() => {
    getCurrentValue();

    // SHOW NOTIFICATION
    if (successSubscribe || successUnSubscribe) {
      dispatch({
        type: UI_NOTIFICATION_SHOW,
        payload: [
          {
            type: "slide",
            color: "#10e092",
            title: successSubscribe
              ? "Вы успешно зарегистрированы!"
              : "Вы успешно отменили регистрацию!",
            icon: "fal fa-check-circle",
          },
        ],
      });
    }
    return () => {
      dispatch({ type: SUBSCRIPTION_RESET });
    };
  }, [dispatch, getCurrentValue, successSubscribe, successUnSubscribe]);

  return isSubscribed;
}

export default useSubscription;
