import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// MIDDLEWARE
import { handleSmoothScroll } from "../../middleware/smoothScroll";

// ACTIONS
import { logout } from "../../redux/actions/userActions";
import { handleSidebar } from "../../redux/actions/uiActions";

// IMAGES
import LogoNav from "../../images/svg/logo-navbar.svg";

function NavFixed() {
  const location = useLocation();
  const dispatch = useDispatch();

  // REDUX STATE
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const sidebar = useSelector((state) => state.ui.sidebar);
  const { isOpen } = sidebar;

  const handleSidebarState = (status) => {
    if (status) {
      dispatch(handleSidebar(false));
    } else {
      dispatch(handleSidebar(true));
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <nav
      className={
        location.pathname === "/"
          ? "nav-fixed-top nav-main-page"
          : "nav-fixed-top"
      }
    >
      <div className="container">
        <button
          className={`navbar__sidebar--open ${
            isOpen ? "sidebar--opened" : null
          }`}
          onClick={() => handleSidebarState(isOpen)}
          aria-label="Открыть меню"
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
        <div className="navbar__menu">
          <Link to="/" className="nav__links--logo">
            <img src={LogoNav} alt="Лого-ссылка" />
          </Link>
          {location.pathname === "/" ? (
            ""
          ) : (
            <Link className="navbar--link" to="/">
              Главная
            </Link>
          )}
          {location.pathname === "/" ? (
            <h6 onClick={() => handleSmoothScroll("#books")}>Книги</h6>
          ) : (
            <Link className="navbar--link" to={"/#books"}>
              Книги
            </Link>
          )}
          {location.pathname === "/" ? (
            <h6 onClick={() => handleSmoothScroll("#coffee")}>Кофе</h6>
          ) : (
            <Link className="navbar--link" to={"/coffee"}>
              Кофе
            </Link>
          )}
          <Link className="navbar--link" to={"/lectorium"}>
            Др. Измерения
          </Link>
        </div>
        {userInfo ? (
          <div className="navbar__profile">
            <i className="far fa-user"></i>
            <i className="far fa-chevron-down"></i>
            <div className="navbar__profile--dropdown">
              {userInfo ? (
                <>
                  <Link to="/user/profile">
                    <i className="far fa-user"></i>Профиль
                  </Link>{" "}
                </>
              ) : null}
              {userInfo && userInfo.user.role === "admin" ? (
                <Link to="/admin">
                  <i className="far fa-lock-alt"></i> Админ
                </Link>
              ) : null}
              <button className="logout" onClick={handleLogout}>
                <i className="far fa-sign-out-alt"></i> Выход
              </button>
            </div>
          </div>
        ) : (
          <div className="navbar__auth">
            <Link className="navbar--link" to="/auth/login">
              Вход
            </Link>
            <Link className="navbar--link" to="/auth/register">
              Регистрация
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
}

export default NavFixed;
